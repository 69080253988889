export default {
  "customer.account_info.about.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous souhaitez modifier votre compte ? Modifier vos coordonnées ? Vous pouvez le faire ici."])},
  "customer.account_info.about.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonjour !"])},
  "customer.account_structore.error.create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seul le compte parent a la possibilité d'afficher et de créer de nouveaux comptes."])},
  "customer.account_structore.error.no_account.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous n'avons trouvé aucun compte"])},
  "customer.account_structore.error.no_account.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun compte"])},
  "customer.account_structore.modal.information.dept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Département / société sœur"])},
  "customer.account_structore.modal.information.team_identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant de l'équipe"])},
  "customer.account_structore.modal.information.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information sur le compte"])},
  "customer.account_structore.modal.settings.terms": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Je souhaite transférer le ", _interpolate(_list(0)), " de ce compte vers le nouveau."])},
  "customer.account_structore.modal.settings.terms.copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Je voudrais copier le ", _interpolate(_list(0)), " de ce compte vers le nouveau."])},
  "customer.account_structore.modal.settings.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglages"])},
  "customer.account_structore.modal.settings.tos.billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["détails de facturation"])},
  "customer.account_structore.modal.settings.tos.price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["liste de prix"])},
  "customer.account_structore.modal.settings.tos.team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["équipe de traduction"])},
  "customer.account_structore.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un sous-compte"])},
  "customer.account_structore.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes comptes"])},
  "customer.ai_file.error_codes.complex_xlsx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XLSX complexe trouvé. Veuillez supprimer le contenu à l'intérieur des balises de script (par ex. <script>)"])},
  "customer.ai_task.download.tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dernier fichier généré : ", _interpolate(_named("timestamp")), " Avant de télécharger le fichier, assurez-vous que l’horodatage ci-dessus est postérieur à vos dernières modifications dans la révision interne. S’il est antérieur, vos modifications ne sont pas incluses. Attendez que l'horodatage soit mis à jour avant de télécharger."])},
  "customer.ai_task.download.wait_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre fichier sera prêt à être téléchargé dans environ", _interpolate(_named("min")), " minutes"])},
  "customer.api.about.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaitez-vous un processus encore plus rapide et plus simple lors de la commande de traductions chez nous ? Avec notre solution API, nous garantissons une intégration transparente au sein de votre propre plateforme. Vos traductions sont à portée de clic !"])},
  "customer.api.about.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technologie intelligente"])},
  "customer.api.api_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien vers la documentation de l'API"])},
  "customer.api.client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° de client"])},
  "customer.api.client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret du client"])},
  "customer.area_of_work.modal.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cela nous aidera à améliorer votre expérience EasyTranslate"])},
  "customer.area_of_work.modal.localisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipe Localisation"])},
  "customer.area_of_work.modal.marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipe Marketing &amp; Design"])},
  "customer.area_of_work.modal.operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipe Opérations"])},
  "customer.area_of_work.modal.other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
  "customer.area_of_work.modal.product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipe Produit"])},
  "customer.area_of_work.modal.tech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipe Software &amp; Technique"])},
  "customer.area_of_work.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans quelle équipe êtes-vous ?"])},
  "customer.automation.ai_translation.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez notre traduction IA pour traduire votre contenu"])},
  "customer.automation.ai_translation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduction HumanAI"])},
  "customer.automation.complete_project.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finir le projet"])},
  "customer.automation.complete_project.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminer le projet"])},
  "customer.automation.create_caas_project.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer un texte de description de produit à partir de mots clés"])},
  "customer.automation.create_caas_project.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Génération de contenu"])},
  "customer.automation.create_project.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléversez votre contenu à traduire"])},
  "customer.automation.create_project.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléversez le fichier source"])},
  "customer.automation.inital.step.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclencheurs d'inscription"])},
  "customer.automation.inital.step.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les déclencheurs qui lanceront votre flux de travail."])},
  "customer.automation.internal_caas_review.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passez en revue votre contenu généré"])},
  "customer.automation.internal_caas_review.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examen du contenu interne"])},
  "customer.automation.internal_review.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demandez à votre propre utilisateur interne de réviser le contenu traduit"])},
  "customer.automation.internal_review.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révision interne"])},
  "customer.automation.machine_translation.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez notre traduction automatique pour traduire votre contenu"])},
  "customer.automation.machine_translation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduction automatique (TA)"])},
  "customer.automation.project_action.assets_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Si aucun ", _interpolate(_named("asset")), " n'existe encore, accédez à <i>Ressources linguistiques</i> et créez-en un en cliquant sur <i>Ajouter un nouveau</i>"])},
  "customer.automation.project_action.primary_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner le primaire"])},
  "customer.automation.project_action.secondary_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner secondaire"])},
  "customer.automation.project_action.template_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous laissez ce champ vide, un modèle par défaut pour la langue source choisie sera créé"])},
  "customer.automation.qa.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demandez à votre propre fournisseur interne d'effectuer un contrôle de qualité sur un échantillon de contenu traduit"])},
  "customer.automation.qa.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôle de qualité"])},
  "customer.automation.qa_action.accuracy_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précision"])},
  "customer.automation.qa_action.accuracy_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signification erronée, erreurs de chiffres et de références, omissions ou balises incorrectes."])},
  "customer.automation.qa_action.file_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille minimale de l'échantillon"])},
  "customer.automation.qa_action.frequency_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fréquence"])},
  "customer.automation.qa_action.frequency_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détermine la fréquence d'exécution d'une tâche CQ. Par ex., si la fréquence est définie sur 2 sur 10, cela se traduira approximativement par un CQ toutes les 5 tâches. Veuillez noter que la tâche CQ sera toujours comptée en fonction de la langue cible choisie et du sujet choisi pour la réaliser"])},
  "customer.automation.qa_action.language_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
  "customer.automation.qa_action.language_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreurs de ponctuation, d'orthographe, de majuscules ou de grammaire."])},
  "customer.automation.qa_action.locale_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caractère local"])},
  "customer.automation.qa_action.locale_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreurs dans les normes nationales telles que les formats de date, les devises et les aspects culturels."])},
  "customer.automation.qa_action.performing_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traducteur effectuant le CQ"])},
  "customer.automation.qa_action.performing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez noter que la tâche sera attribuée au premier traducteur que vous avez sélectionné. Si ce traducteur refuse, elle sera attribuée aux autres traducteurs que vous avez sélectionnés, par ordre chronologique."])},
  "customer.automation.qa_action.style_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Style"])},
  "customer.automation.qa_action.style_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreurs de style, non-respect de directives spécifiques ou erreurs de registre de langue/de variante de langue."])},
  "customer.automation.qa_action.subject_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traducteur soumis à CQ"])},
  "customer.automation.qa_action.subject_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vous assurer que le traducteur que vous sélectionnez pour le contrôle qualité est le traducteur à qui la tâche a été affectée. Si ce fournisseur refuse, votre tâche CQ sera automatiquement annulée."])},
  "customer.automation.qa_action.terminology_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminologie"])},
  "customer.automation.qa_action.terminology_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisation incohérente de la terminologie ou non-respect du glossaire propre à l’industrie/fourni."])},
  "customer.automation.review_action.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a aucun réviseur dans votre équipe pour cette combinaison. Nous attribuerons automatiquement un réviseur de notre service"])},
  "customer.automation.settings.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez ici modifier les paramètres de votre flux."])},
  "customer.automation.settings.toast.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos paramètres pour cette automatisation ont été mis à jour avec succès"])},
  "customer.automation.settings.toast.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres mis à jour"])},
  "customer.automation.sidebar.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir une action"])},
  "customer.automation.start_string_library_automation.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une automatisation de traduction pour une bibliothèque de chaînes existante"])},
  "customer.automation.start_string_library_automation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothèque de chaînes"])},
  "customer.automation.status_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le flux de travail est"])},
  "customer.automation.step_complete.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez configuré avec succès votre étape de flux de travail"])},
  "customer.automation.step_complete.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez configuré avec succès votre étape de flux de travail"])},
  "customer.automation.step_complete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étape terminée"])},
  "customer.automation.step_incomplete.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez vos paramètres pour l'étape de flux de travail d'automatisation"])},
  "customer.automation.step_incomplete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étape incomplète"])},
  "customer.automation.string_library.action.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour générer du contenu, sélectionnez la bibliothèque du produit."])},
  "customer.automation.string_library.action.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothèques"])},
  "customer.automation.string_library.step.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclencheur de traduction ou de produit défini sur"])},
  "customer.automation.supplier_review.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez l'un de nos fournisseurs pour réviser votre contenu traduit"])},
  "customer.automation.supplier_review.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révision"])},
  "customer.automation.supplier_translation.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez l'un de nos fournisseurs pour traduire votre contenu"])},
  "customer.automation.supplier_translation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduction"])},
  "customer.automation.unsupported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flux de travail non pris en charge par l'abonnement"])},
  "customer.automations.empty.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez créé aucun flux."])},
  "customer.automations.empty.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de flux"])},
  "customer.automations.steps.ai_translation.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez la traduction basée sur l'IA pour une traduction de contenu rapide et automatisée."])},
  "customer.automations.steps.complete_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminer le projet"])},
  "customer.automations.steps.complete_project.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalisez votre flux de travail. Après cette étape, seule une révision interne peut être ajoutée."])},
  "customer.automations.steps.create_caas_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Génération de contenu"])},
  "customer.automations.steps.create_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléverser le fichier source"])},
  "customer.automations.steps.create_project.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez une étape de téléchargement de fichier pour fournir du contenu à traduire."])},
  "customer.automations.steps.internal_caas_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révision du contenu interne"])},
  "customer.automations.steps.internal_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révision interne"])},
  "customer.automations.steps.internal_review.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effectuer une révision interne du contenu traduit. Cette action ne peut être effectuée qu'après avoir terminé le projet."])},
  "customer.automations.steps.machine_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduction automatique (TA)"])},
  "customer.automations.steps.machine_translation.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduisez automatiquement le contenu à l'aide de la traduction automatique."])},
  "customer.automations.steps.qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôle qualité"])},
  "customer.automations.steps.qa.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez une tâche d'assurance qualité pour qu'un pigiste examine des échantillons d'assurance qualité du contenu traduit avant de le finaliser. Disponible uniquement après traduction humaine."])},
  "customer.automations.steps.start_string_library_automation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothèque de chaînes"])},
  "customer.automations.steps.start_string_library_automation.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduisez le contenu de votre bibliothèque de chaînes dans les workflows. Créez un flux avant d’envoyer vos chaînes à la traduction."])},
  "customer.automations.steps.supplier_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révision"])},
  "customer.automations.steps.supplier_review.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désignez un pigiste pour réviser et peaufiner la traduction. Disponible uniquement après traduction humaine."])},
  "customer.automations.steps.supplier_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduction"])},
  "customer.automations.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un flux"])},
  "customer.balance.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de solde à afficher."])},
  "customer.balance.type.adjustment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustement"])},
  "customer.balance.type.applied_to_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliqué à la facture"])},
  "customer.balance.type.credit_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note de crédit"])},
  "customer.banner.book_meeting.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Besoin d'aide ou des questions ? Prenez rendez-vous avec Customer Success ", _interpolate(_named("link"))])},
  "customer.billing.about.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettez à jour vos informations de facturation et votre mode de paiement."])},
  "customer.billing.about.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des paiements simplifiés"])},
  "customer.billing.information.billing_portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher le portail de facturation"])},
  "customer.billing.information.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de facturation"])},
  "customer.billing.methods.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un mode de paiement"])},
  "customer.billing.methods.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez et gérez vos moyens de paiement grâce à notre système de paiement sécurisé."])},
  "customer.billing.methods.payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir les modes de paiement"])},
  "customer.billing.methods.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyens de payement"])},
  "customer.billing.missing_billing.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez les informations de facturation manquantes pour commencer la formation MT"])},
  "customer.billing.missing_billing.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de facturation manquantes"])},
  "customer.build_team.copywriters.selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez sélectionné ", _interpolate(_named("number")), " rédacteur.rice.s"])},
  "customer.build_team.copywriters.text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons soigneusement sélectionné nos meilleur.e.s rédacteur.rice.s, mais vous pouvez toujours modifier notre recommandation."])},
  "customer.build_team.copywriters.text_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cliquant sur un.e rédacteur.rice, vous pouvez voir son profil détaillé."])},
  "customer.build_team.copywriters.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez votre ou vos rédacteur.rices.s préféré.e.s"])},
  "customer.build_team.copywriters_confirm.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le ou les rédacteur.rice.s sélectionné.e.s"])},
  "customer.build_team.copywriting.text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez un.e rédacteur.rice à votre équipe"])},
  "customer.build_team.page_five.text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun fournisseur n’est disponible pour la combinaison linguistique demandée, veuillez nous contacter via le chat pour voir si nous pouvons vous aider."])},
  "customer.build_team.page_five.text_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le ou les traducteurs suivants vont être ajoutés à votre équipe."])},
  "customer.build_team.page_five.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le ou les traducteurs sélectionnés"])},
  "customer.build_team.page_four.text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez un éventuel chargé du contrôle de qualité pour votre traduction"])},
  "customer.build_team.page_four.text_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le ou les traducteurs sélectionnés ne proposent pas de services de contrôle de qualité"])},
  "customer.build_team.page_four.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôle de qualité"])},
  "customer.build_team.page_one.file_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez noter que votre fichier ne peut pas dépasser 300 Ko"])},
  "customer.build_team.page_one.text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez téléverser un fichier afin que nous puissions traiter votre texte avec notre technologie basée sur l'IA."])},
  "customer.build_team.page_one.text_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez noter que cela ne créera pas de projet, mais que cela constituera votre équipe de traduction."])},
  "customer.build_team.page_one.text_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De cette façon, nous assurerons la meilleure adéquation entre vous et nos traducteurs."])},
  "customer.build_team.page_three.text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez un éventuel relecteur pour votre traduction"])},
  "customer.build_team.page_three.text_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le ou les traducteurs sélectionnés ne proposent pas de services de révision"])},
  "customer.build_team.page_three.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revoir la traduction"])},
  "customer.build_team.page_two.selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez sélectionné ", _interpolate(_named("number")), " traducteurs"])},
  "customer.build_team.page_two.text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons soigneusement sélectionné nos meilleurs traducteurs, mais vous pouvez toujours modifier notre recommandation."])},
  "customer.build_team.page_two.text_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cliquant sur un traducteur, vous pouvez voir son profil détaillé."])},
  "customer.build_team.page_two.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez votre ou vos traducteurs préférés"])},
  "customer.button.calculate.cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculer le coût du projet"])},
  "customer.caas_key_modal.library_keywords.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapez les mots clés du produit pour la bibliothèque de produits, séparés par des virgules. Utilisez au minimum 5 mots clés et au maximum 25. Pour augmenter la qualité du texte généré, nous suggérons d'utiliser les mots clés dans un ordre logique."])},
  "customer.caas_key_modal.library_keywords.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots clés pour la bibliothèque de produits"])},
  "customer.caas_key_modal.sku_tooltip.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez votre nom de produit unique (UGS) pour créer une clé unique pour la bibliothèque de produits"])},
  "customer.caas_key_modal.sku_tooltip.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom clé du produit / UGS"])},
  "customer.content_generation.document.create.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le document a bien été créé"])},
  "customer.content_generation.document.delete.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le document a bien été supprimé"])},
  "customer.content_generation.document.name.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom du document a bien été mis à jour"])},
  "customer.content_generation.template.delete.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modèle a bien été supprimé"])},
  "customer.content_generation.template.save.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modèle a bien été enregistré"])},
  "customer.content_pillars.button.create_pillar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez un nouveau pilier"])},
  "customer.content_pillars.button.save_pillar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrez le pilier"])},
  "customer.content_pillars.buttons.create_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez un(e)"])},
  "customer.content_pillars.clusters_overview.add_pages_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez des pages"])},
  "customer.content_pillars.clusters_overview.cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulé"])},
  "customer.content_pillars.clusters_overview.completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminé"])},
  "customer.content_pillars.clusters_overview.in_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À l’état de brouillon"])},
  "customer.content_pillars.clusters_overview.in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])},
  "customer.content_pillars.create_content.add_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez des paramètres de contenu"])},
  "customer.content_pillars.create_content.button.show_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passer à l'aperçu"])},
  "customer.content_pillars.create_content.chars_count_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les champs « Caractères » et « Mots » sont vides. L'un d'entre eux doit être supérieur à 0"])},
  "customer.content_pillars.create_content.drag_to_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faites glisser ou utilisez les flèches pour sélectionner"])},
  "customer.content_pillars.create_content.enter_wordcount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combien de mots/caractères ?"])},
  "customer.content_pillars.create_content.results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat final"])},
  "customer.content_pillars.create_content.results_pick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez-en un, plusieurs ou tous, et commençons !"])},
  "customer.content_pillars.create_content.select_social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de contenu"])},
  "customer.content_pillars.create_content.select_suggestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le nombre de suggestions"])},
  "customer.content_pillars.create_content.select_wordcount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez les paramètres de votre contenu"])},
  "customer.content_pillars.create_content.suggestions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Suggestions"])},
  "customer.content_pillars.create_content.suggestions_happy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cela vous convient ?"])},
  "customer.content_pillars.create_content.suggestions_pick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez ceux qui vous conviennent"])},
  "customer.content_pillars.create_content.suggestions_regenerate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essayez à nouveau"])},
  "customer.content_pillars.label.add_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez du contenu"])},
  "customer.content_pillars.label.describe_pillar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rédigez une brève description de votre entreprise ou entrez votre URL"])},
  "customer.content_pillars.label.describe_pillar_keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous générerons des mots-clés à partir de cela"])},
  "customer.content_pillars.label.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je suis vide"])},
  "customer.content_pillars.label.name_pillar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nommez votre pilier"])},
  "customer.content_pillars.no_copyeditors": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous n’avez aucun.e rédacteur.trice dans votre équipe de traducteur.trice.s. Cliquez sur ", _interpolate(_named("here")), " pour ajouter un.e rédacteur.trice indépendant.e à votre équipe."])},
  "customer.copyediting.animation.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous souhaitez envoyer votre contenu pour édition ?"])},
  "customer.create_flow.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un flux"])},
  "customer.create_flow.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nommez votre flux et ajoutez-lui une description"])},
  "customer.create_flow.name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du flux"])},
  "customer.create_flow.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez votre flux ici"])},
  "customer.create_project.modal.missing_translators": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous n'avez pas d'équipe de traducteurs. Cliquez ", _interpolate(_named("here")), " pour ajouter un.e traducteur.rice à votre équipe ou sélectionnez Traduction automatique"])},
  "customer.document.modal.name_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom du document existe déjà"])},
  "customer.feature.animation.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette fonctionnalité est incluse dans votre forfait"])},
  "customer.feature.automation_workflow.cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez ci-dessous pour configurer votre premier Automation Workflow !"])},
  "customer.feature.automation_workflow.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éliminez le besoin de gestion manuelle des workflows récurrents et gagnez un temps précieux. Cette fonctionnalité d'automatisation vous permet de rationaliser vos processus, en garantissant que chaque tâche est exécutée de manière cohérente et précise."])},
  "customer.feature.automation_workflow.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gagnez en productivité et en efficacité !"])},
  "customer.feature.cg.cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez ci-dessous pour générer du contenu !"])},
  "customer.feature.cg.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content Generation est un outil de rédaction basé sur l'IA qui génère des textes convaincants pour les articles de blog, les descriptions de produits, les e-mails de vente, les publications sur les réseaux sociaux, le lead nurturing, les publicités, etc. En option, il se connecte à un marché en ligne de rédacteur.rice.s pour une personnalisation supplémentaire."])},
  "customer.feature.cg.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez du contenu attrayant en un tour de main"])},
  "customer.feature.chat.cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez ci-dessous pour commencer !"])},
  "customer.feature.chat.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une communication efficace est essentielle lorsque l’on travaille sur une tâche, car elle garantit que toutes les personnes impliquées comprennent leurs rôles et responsabilités. Une bonne communication augmente non seulement la productivité, mais améliore également le travail d'équipe et favorise un environnement de travail positif. Communiquez avec vos traducteur.rice.s via notre fonction de message."])},
  "customer.feature.chat.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communiquez efficacement avec vos freelances"])},
  "customer.feature.content_pillars.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centralisez votre contenu autour de votre domaine de travail et laissez-nous générer plusieurs textes au contenu différent pour le même sujet ! Il vous suffira de définir le ou les titre ainsi que le nombre de mots. Le reste est laissé à l’imagination de vos rédacteur.rice.s."])},
  "customer.feature.content_pillars.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construisez la feuille de route pour optimiser votre pour le référencement !"])},
  "customer.feature.cta_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commençons"])},
  "customer.feature.freelancers.cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez ci-dessous pour ajouter un freelance !"])},
  "customer.feature.freelancers.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez un freelance à votre équipe si vous souhaitez ajouter une touche humaine à l'un de vos types de tâches ! Faites votre choix parmi nos nombreux freelances qui traduisent, rédigent ou révisent des tâches."])},
  "customer.feature.freelancers.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin d'une touche humaine ?"])},
  "customer.feature.mt.cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez ci-dessous pour en savoir plus !"])},
  "customer.feature.mt.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Cette fonctionnalité nécessite que vous disposiez d’une mémoire de traduction stockée chez nous."])},
  "customer.feature.mt.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un moteur de traduction automatique classique fait passer votre contenu par un moteur générique, ce qui, dans la plupart des cas, ne produit pas les meilleurs résultats lorsque votre contenu est plus que générique. Ce moteur, quant à lui, accède à votre mémoire de traduction et lui apprend à privilégier les entrées de vos traductions précédentes. Cela signifie que le sujet de votre traduction reprend en priorité vos données existantes chez nous."])},
  "customer.feature.mt.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disposez de votre propre moteur de traduction automatique personnalisé pour générer des traductions automatiques puissantes !"])},
  "customer.feature.string_library.cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez ci-dessous pour créer une bibliothèque !"])},
  "customer.feature.string_library.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il s'agit d'un excellent outil pour les développeur.se.s ainsi que pour toute personne travaillant avec du contenu. Gérez vos clés et valeurs en un seul endroit. L'utilisation d'une String Library simplifie considérablement les processus, rendant votre code et votre contenu plus propres et plus efficaces. Cela contribue également à réduire les risques d’erreurs."])},
  "customer.feature.string_library.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centralisez votre contenu en un seul endroit !"])},
  "customer.folder.modal.name_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce nom de dossier existe déjà"])},
  "customer.folders.empty.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun dossier créé."])},
  "customer.folders.empty.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun dossier"])},
  "customer.freemium.upgrade_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous pour mettre à niveau"])},
  "customer.freemium_modal.header_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous trouverez ci-dessous une liste des différents niveaux de tarification pour cette fonctionnalité"])},
  "customer.freemium_modal.tier_table.price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix / mois"])},
  "customer.freemium_modal.tier_table.tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
  "customer.freemium_modal.tier_table.usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite d'utilisation"])},
  "customer.history.billing_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de facturation"])},
  "customer.history.date_issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de publication"])},
  "customer.history.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun historique à afficher"])},
  "customer.history.price_vat_excl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix (HT)"])},
  "customer.history.status.draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brouillon"])},
  "customer.history.status.open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvert"])},
  "customer.history.status.paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payé"])},
  "customer.history.status.uncollectible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irrécouvrable"])},
  "customer.history.status.void": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalide"])},
  "customer.history.type.project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projet"])},
  "customer.history.type.subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abonnement"])},
  "customer.history.type.wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portefeuille"])},
  "customer.invitation.empy.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite : cette invitation n'existe pas ou elle a expiré"])},
  "customer.invitation.empy.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur d'invitation"])},
  "customer.invitation.team.error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre invitation à ", _interpolate(_named("team")), " a bien été refusée"])},
  "customer.invitation.team.login": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cliquez sur ", _interpolate(_named("link")), " aller à la connexion"])},
  "customer.invitation.team.send_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une invitation a été envoyée à ", _interpolate(_named("email"))])},
  "customer.invitation.team.success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Réussi ! Vous avez maintenant rejoint ", _interpolate(_named("team"))])},
  "customer.invitation.team.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("team")), " vous a invité(e) à faire partie de son compte."])},
  "customer.label.reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réattribuer"])},
  "customer.label.word_credits": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("feature")), " Crédits Word"])},
  "customer.lang_assets.actions_modal.export.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seuls les utilisateurs intégrés et les administrateurs ET peuvent recevoir l'exportation. Vous pouvez l'envoyer à votre propre e-mail ou saisir l'e-mail d'un autre utilisateur éligible. Le processus peut prendre du temps en fonction de la taille du fichier."])},
  "customer.lang_assets.actions_modal.export.option_my_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le choix de cette option enverra le fichier à votre adresse e-mail"])},
  "customer.lang_assets.actions_modal.export.option_other_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez cette option pour saisir l'e-mail d'un autre utilisateur intégré ou l'e-mail de l'administrateur ET."])},
  "customer.lang_assets.actions_modal.export.other_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer l'exportation à un autre utilisateur"])},
  "customer.lang_assets.actions_modal.export_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer l'exportation vers (facultatif)"])},
  "customer.lang_assets.actions_modal.export_tootlip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'exportation sera envoyée à l'adresse e-mail fournie. Si aucune adresse e-mail n'est saisie, elle sera envoyée à votre adresse e-mail."])},
  "customer.lang_assets.actions_modal.import": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous êtes sur le point d'importer ", _interpolate(_named("assetType")), " dans ", _interpolate(_named("asset"))])},
  "customer.lang_assets.add_start.start.file_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nommez votre (vos) fichier(s)"])},
  "customer.lang_assets.add_start.start.glossary_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nommez vos glossaires"])},
  "customer.lang_assets.add_start.start.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargement manuel de fichiers bilingues"])},
  "customer.lang_assets.add_start.start.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer à partir de zéro!"])},
  "customer.lang_assets.add_start.upload.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("upload")), " vos atouts"])},
  "customer.lang_assets.case_sensitivity.confirmation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Voulez-vous ", _interpolate(_named("indiquer")), " la sensibilité à la casse ?"])},
  "customer.lang_assets.case_sensitivity.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sensibilité à la casse"])},
  "customer.lang_assets.content_type.modal.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Voulez-vous modifier le type d’entrée en ", _interpolate(_named("type")), "?"])},
  "customer.lang_assets.upload.instructions.languages_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Assurez-vous de vérifier que vos codes de langues sont alignés avec les nôtres. Cliquez sur", _interpolate(_named("here")), " pour voir notre cartographie linguistique"])},
  "customer.lang_assets.upload.instructions.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment votre fichier .csv doit-il être structuré ?"])},
  "customer.lang_assets.upload.instructions_tb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaque langue doit être placée dans sa propre colonne"])},
  "customer.lang_assets.upload.instructions_tb.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez noter que nous n'acceptons que les fichiers .csv lors du téléchargement de glossaires."])},
  "customer.lang_assets.upload.instructions_tm.a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La colonne A doit contenir le segment source"])},
  "customer.lang_assets.upload.instructions_tm.b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La colonne B doit contenir la traduction précédente (le cas échéant, ou bien vide)"])},
  "customer.lang_assets.upload.instructions_tm.c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La colonne C doit contenir le segment suivant (le cas échéant, ou bien vide)"])},
  "customer.lang_assets.upload.instructions_tm.d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La colonne D doit contenir la première langue cible"])},
  "customer.lang_assets.upload.instructions_tm.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez noter que nous n'acceptons que les fichiers .csv lors du téléchargement de MT."])},
  "customer.lang_assets.upload.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez en ajouter plusieurs, mais un à la fois"])},
  "customer.lang_assets.upload.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Téléchargez votre ", _interpolate(_named("type"))])},
  "customer.language_assets.import_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer un nouveau"])},
  "customer.language_assets.term_bank.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glossaires"])},
  "customer.language_assets.translation_memory.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mémoire de traduction"])},
  "customer.libraries.btn.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une bibliothèque"])},
  "customer.libraries.empty.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune bibliothèque créée."])},
  "customer.libraries.empty.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de bibliothèque"])},
  "customer.libraries.modal.create_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une bibliothèque de produits"])},
  "customer.libraries.modal.create_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une bibliothèque de traduction"])},
  "customer.libraries.modal.delete_language.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la langue"])},
  "customer.libraries.modal.delete_library.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la bibliothèque"])},
  "customer.libraries.modal.edit.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les informations de votre bibliothèque de traduction"])},
  "customer.libraries.modal.name_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce nom de bibliothèque existe déjà"])},
  "customer.libraries.modal.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir toutes les informations requises"])},
  "customer.libraries.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez votre bibliothèque"])},
  "customer.libraries.title.add_new_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une nouvelle langue"])},
  "customer.library.caas.btn.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une clé de produit"])},
  "customer.library.caas.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit"])},
  "customer.library.shared.btn.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger la bibliothèque"])},
  "customer.library.shared.btn.start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrer l'automatisation"])},
  "customer.library.shared.modal.delete.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Etes-vous sûr de vouloir supprimer ", _interpolate(_named("name"))])},
  "customer.library.shared.modal.delete_key.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la clé"])},
  "customer.library.shared.modal_download.nest_strings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaînes suivantes"])},
  "customer.library.shared.modal_download.option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez votre option préférée"])},
  "customer.library.shared.modal_download.option_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger toutes les langues"])},
  "customer.library.shared.modal_download.option_optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir les langues"])},
  "customer.library.shared.modal_download.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous êtes sur le point de télécharger la bibliothèque ", _interpolate(_named("name")), " en tant que fichier ", _interpolate(_named("json")), "."])},
  "customer.library.shared.modal_download.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger la bibliothèque"])},
  "customer.library.shared.modal_start.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous êtes sur le point de démarrer l'automatisation sur ", _interpolate(_named("name"))])},
  "customer.library.shared.modal_start.text_workflow": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous êtes sur le point de démarrer le flux de travail d'automatisation ", _interpolate(_named("workflow")), " pour votre bibliothèque de chaînes ", _interpolate(_named("name"))])},
  "customer.library.shared.modal_start.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flux d'automatisation"])},
  "customer.library.shared.modal_start_filtered.text_workflow": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous êtes sur le point de démarrer le flux de travail d'automatisation ", _interpolate(_named("workflow")), " pour toutes les chaînes correspondant à votre filtre"])},
  "customer.library.shared.modal_start_selected.text_workflow": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous êtes sur le point de démarrer le flux de travail d'automatisation ", _interpolate(_named("workflow")), " pour les chaînes sélectionnées"])},
  "customer.library.translation.btn.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une clé"])},
  "customer.library.translation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduction"])},
  "customer.machine_translation.custom_mt.view.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer l’entraînement de la MT"])},
  "customer.machine_translation.custom_mt.view.cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler l’entraînement"])},
  "customer.machine_translation.custom_mt.view.cancel_training_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’entraînement du modèle sélectionné a été annulé"])},
  "customer.machine_translation.custom_mt.view.estimate_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimer le coût"])},
  "customer.machine_translation.custom_mt.view.start_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer la formation"])},
  "customer.machine_translation.custom_mt.view.success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre modèle d’entraînement a commencé"])},
  "customer.marketplace.app.benner.paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payé"])},
  "customer.marketplace.app.blackbird.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Que vous cherchiez à créer du contenu à partir de rien ou à traduire votre site Web, votre boutique en ligne, votre SGC ou autre chose, nous disposons de tous les outils dont vous avez besoin pour réussir. Intégrez nos nombreux plug-ins sans code et automatisez l'ensemble de vos processus de traduction et de génération de contenu."])},
  "customer.marketplace.app.contentful.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre plugin Contentful vous permet de synchroniser votre contenu à partir de vos types de contenu vers notre bibliothèque de chaînes, où vous pouvez appliquer nos fonctionnalités dans la bibliothèque de chaînes pour automatiser sa traduction ou le traduire vous-même, avant de le réimporter vers Contentful. C'est aussi simple que de cliquer sur deux boutons."])},
  "customer.marketplace.app.episerver.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez EasyTranslate à votre site Episerver et envoyez et recevez instantanément des traductions. Veuillez noter que l'installation de ce plugin téléchargera un fichier zip que vous devrez installer."])},
  "customer.marketplace.app.figma.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre plug-in Figma vous permet de vous connecter à une bibliothèque, de synchroniser vos strings, de les faire traduire et de les renvoyer, le tout sans avoir à quitter Figma"])},
  "customer.marketplace.app.github.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GitHub est notre façon d'aider les développeurs à automatiser leurs processus de traduction. Il vous aide à traduire vos fichiers de localisation à partir de votre code base en utilisant uniquement votre langue source, puis à créer les fichiers traduits nécessaires dans votre langue cible."])},
  "customer.marketplace.app.hygraph.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre plugin Hygraph vous permet d'envoyer tout le contenu de votre site Web vers notre plateforme en quelques clics seulement. Le contenu entre dans notre bibliothèque de chaînes, est traduit, puis renvoyé à Hygraph."])},
  "customer.marketplace.app.magento_1.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez EasyTranslate à votre site Web d’e-commerce Magento pour envoyer et recevoir instantanément des traductions. Veuillez noter que l'installation de ce plugin téléchargera un fichier zip que vous devrez installer."])},
  "customer.marketplace.app.magento_2.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez EasyTranslate à votre site Web d’e-commerce Magento pour envoyer et recevoir instantanément des traductions."])},
  "customer.marketplace.app.shopify.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez EasyTranslate à votre boutique en ligne Shopify pour recevoir des traductions directement dans votre back-end."])},
  "customer.marketplace.app.shopware.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez EasyTranslate à votre site Web d’e-commerce Shopware pour envoyer et recevoir instantanément des traductions. Veuillez noter que l'installation de ce plugin téléchargera un fichier zip que vous devrez installer."])},
  "customer.marketplace.app.wordpress.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simplifiez-vous la vie en connectant EasyTranslate à votre site WordPress pour envoyer et recevoir instantanément des traductions."])},
  "customer.marketplace.app.zapier.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapier vous permet de vous connecter à EasyTranslate pour intégrer automatiquement les traductions dans les applications que vous utilisez chaque jour."])},
  "customer.marketplace.item.kb": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("app_text")), " Pour en savoir plus, cliquez ici ", _interpolate(_named("link"))])},
  "customer.marketplace.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Visitez notre ", _interpolate(_named("link")), " pour en savoir plus sur l'installation et l'intégration de notre plugin dans votre système."])},
  "customer.marketplace.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisez vos processus de travail et augmentez vos performances grâce à nos différentes intégrations transparentes de plug-ins dans votre système."])},
  "customer.messages.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos traducteurs sont prêts à communiquer intelligemment avec vous concernant des tâches spécifiques"])},
  "customer.modal.freemium_accounts.ai.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrez l’avenir de la traduction avec HumanAI !"])},
  "customer.multilingual_project.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le fichier multilingue n'est pas disponible tant que la traduction dans toutes les langues n’est pas terminée. Si vous avez besoin de certaines des langues déjà traduites, veuillez contacter l'assistance."])},
  "customer.nav.account.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
  "customer.nav.automation_flows.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flux d'automatisation"])},
  "customer.nav.automation_workflows.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisation des flux de travail"])},
  "customer.nav.copywriting.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rédaction"])},
  "customer.nav.dashboard.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "customer.nav.getStarted.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrer"])},
  "customer.nav.libraries.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothèques"])},
  "customer.nav.marketplace.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appli Marketplace"])},
  "customer.nav.overview.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espace de travail"])},
  "customer.nav.overview.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espace de travail"])},
  "customer.nav.payment.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement"])},
  "customer.nav.pricing.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarification"])},
  "customer.nav.progress.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progression"])},
  "customer.nav.progress.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "customer.nav.string_libraries.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothèques de strings"])},
  "customer.nav.translation_assets.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre moteur de traduction automatique personnalisé"])},
  "customer.nav.translators.breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une équipe"])},
  "customer.nav.translators.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traducteurs"])},
  "customer.news.02-10-2023.task_layout.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-th-large"])},
  "customer.news.02-10-2023.task_layout.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons tenu compte de votre feedback. Nous venons de publier une nouvelle disposition de l’aperçu de votre projet. Vous pouvez désormais facilement voir à quelle étape se trouve votre projet, évaluer facilement votre freelance, appliquer l'éditeur et télécharger votre projet."])},
  "customer.news.02-10-2023.task_layout.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle disposition des tâches !"])},
  "customer.news.04-09-2023.automation_workflow_fix.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file"])},
  "customer.news.04-09-2023.automation_workflow_fix.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez désormais utiliser notre fonctionnalité de bibliothèque de chaînes automatique. Définissez votre seuil et une fois celui-ci dépassé, nous lancerons automatiquement le processus de traduction."])},
  "customer.news.04-09-2023.automation_workflow_fix.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction des workflows d'automatisation !"])},
  "customer.news.04-09-2023.invite_colleagues.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-user-friends"])},
  "customer.news.04-09-2023.invite_colleagues.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons ajouté « Inviter des utilisateur.rice.s » à la barre latérale afin que vous puissiez facilement ajouter des collègues sur la plateforme."])},
  "customer.news.04-09-2023.invite_colleagues.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitez vos collègues sur la plateforme"])},
  "customer.news.04-09-2023.kb_access.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-question"])},
  "customer.news.04-09-2023.kb_access.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des questions sans réponse ? Vous pouvez désormais accéder à la Knowledge Base depuis la barre latérale."])},
  "customer.news.04-09-2023.kb_access.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès rapide à notre base de connaissances !"])},
  "customer.news.04-09-2023.mt_preselect.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-microchip"])},
  "customer.news.04-09-2023.mt_preselect.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez désormais présélectionner, lors de la création d’un projet, les langues pour la traduction automatique. ATTENTION ! Il s'agit d'une fonctionnalité Entreprise."])},
  "customer.news.04-09-2023.mt_preselect.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle fonctionnalité Entreprise !"])},
  "customer.news.04-09-2023.remove_supplier.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-user"])},
  "customer.news.04-09-2023.remove_supplier.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En tant qu'administrateur d'équipe, vous avez désormais la possibilité de supprimer vous-même des prestataires de votre équipe."])},
  "customer.news.04-09-2023.remove_supplier.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimez des prestataires de votre équipe"])},
  "customer.news.06-07-2022.data.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-user-shield"])},
  "customer.news.06-07-2022.data.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le processus manuel consistant à demander au service client l’accès à vos données personnelles n'est plus nécessaire. Il vous suffit d’exporter vos données si vous souhaitez savoir quelles informations ont été stockées. Vous pouvez désormais également demander la suppression de vos données en quelques clics - directement sur notre plateforme."])},
  "customer.news.06-07-2022.data.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accédez à vos données personnelles"])},
  "customer.news.06-07-2022.sso.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-user"])},
  "customer.news.06-07-2022.sso.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous à notre plateforme de manière simple mais sécurisée ! L'authentification unique (SSO) est une méthode d'identification qui vous permet de vous connecter à plusieurs applications en ne procédant qu’à une seule identification. La fonction social login vous permet de vous authentifier sur diverses applications à l’aide des informations existantes d'un service de réseau social. Gardez à l'esprit que cela ne fonctionne qu'avec Gmail pour le moment."])},
  "customer.news.06-07-2022.sso.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion SSO"])},
  "customer.news.06-07-2022.two_factor.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-user-shield"])},
  "customer.news.06-07-2022.two_factor.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous fournissons désormais 2FA pour un accès plus sûr à votre compte ! Cela vous permet de vérifier votre identité deux fois lors de la connexion à la plateforme. Tout d'abord, avec un nom d'utilisateur et un mot de passe. Deuxièmement, en approuvant la demande d'authentification grâce à une application d'authentification sur votre téléphone. 2FA protège contre le phishing, l'ingénierie sociale et les attaques par force brute sur les mots de passe. Il sécurise également votre connexion, car de nombreux mots de passe sont malheureusement trop faibles et trop faciles à deviner."])},
  "customer.news.06-07-2022.two_factor.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification à deux facteurs"])},
  "customer.news.07-07-2022.new_privacy_terms.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-list"])},
  "customer.news.07-07-2022.new_privacy_terms.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://easytranslate.com/en/terms-and-conditions-localisation-software-platform/"])},
  "customer.news.07-07-2022.new_privacy_terms.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nous avons apporté des modifications à nos Conditions générales. Veuillez les lire attentivement ici : ", _interpolate(_named("link"))])},
  "customer.news.07-07-2022.new_privacy_terms.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions générales"])},
  "customer.news.08-12-2020.translate_yourself.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file-alt"])},
  "customer.news.08-12-2020.translate_yourself.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous introduisons maintenant un flux de travail supplémentaire pour votre processus de traduction appelé Traduisez vous-même. Vous pouvez maintenant choisir Traduisez vous-même dans le project creation overview, ce qui permet à votre projet d'être traduit automatiquement et renvoyé directement vers votre éditeur sans aucune étape manuelle. Cette option vous donne la possibilité de faire appel de manière transparente à des traducteurs internes pour effectuer des traductions instantanées plus petites avec les mêmes outils efficaces."])},
  "customer.news.08-12-2020.translate_yourself.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduisez vous-même"])},
  "customer.news.12-06-2023.terms.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-list"])},
  "customer.news.12-06-2023.terms.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons légèrement modifié nos Conditions générales. Rendez-vous dans la section Durée et résiliation pour voir de quoi il s’agit."])},
  "customer.news.12-06-2023.terms.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petite mise à jour de nos CGV !"])},
  "customer.news.13-07-2022.github_action.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-code"])},
  "customer.news.13-07-2022.github_action.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GitHub est notre façon d'aider les développeurs à automatiser leurs processus de traduction. Il vous aide à traduire vos fichiers de localisation à partir de votre code base en utilisant uniquement votre langue source, puis à créer les fichiers traduits nécessaires dans votre langue cible."])},
  "customer.news.13-07-2022.github_action.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action Github"])},
  "customer.news.13-08-2020.payment_history.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file-invoice"])},
  "customer.news.13-08-2020.payment_history.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est maintenant possible d'obtenir un aperçu complet de toutes les factures du projet. Pour obtenir la vision d’ensemble, rendez-vous sur Réglages, sélectionnez l’onglet Payment puis le sous-onglet Historique. Vous pouvez voir toutes les factures de votre compte dans un aperçu simple. Vous pouvez également télécharger les factures dans un format prêt à imprimer."])},
  "customer.news.13-08-2020.payment_history.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique de paiements"])},
  "customer.news.13-08-2020.rating.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-star"])},
  "customer.news.13-08-2020.rating.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sommes ravis de lancer notre nouvelle fonctionnalité - ratings. Ratings permet d’envoyer des commentaires au traducteur sur chaque completed projet. Le traducteur ne peut pas voir chaque évaluation, mais il peut voir la moyenne. Nous utilisons vos évaluations pour classer vos traducteurs selon un domaine d'expertise et pour identifier les futures formations susceptibles d'améliorer leurs compétences."])},
  "customer.news.13-08-2020.rating.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluation"])},
  "customer.news.13-08-2020.review_editor.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-folder"])},
  "customer.news.13-08-2020.review_editor.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous pouvons enfin dévoiler notre première version de EasyTranslate Review Editor, qui vous donne la possibilité de réviser les traductions effectuées par votre équipe de traducteurs. Les révisions sont mises à jour dans votre mémoire de traduction pour une amélioration instantanée des futures traductions. N'hésitez pas à faire part de vos commentaires relatifs à cette grande fonctionnalité à votre gestionnaire de compte."])},
  "customer.news.13-08-2020.review_editor.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditeur de révision"])},
  "customer.news.13-10-2023.content_pillars.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-microchip"])},
  "customer.news.13-10-2023.content_pillars.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un outil de feuille de route pour le contenu optimisé SEO – Les piliers de contenu ! Nous avons créé un outil qui centralise votre contenu autour de votre domaine de travail, afin que vous puissiez créer plusieurs éléments de contenu en masse. Tout ce que vous avez à faire est de définir vos paramètres ; votre rédacteur.rice s’occupe du reste."])},
  "customer.news.13-10-2023.content_pillars.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous venons d'ajouter une nouvelle fonctionnalité : les piliers de contenu !"])},
  "customer.news.14-02-2023.caas.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-cog"])},
  "customer.news.14-02-2023.caas.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons ajouté une barre d'outils afin que vous puissiez créer un contenu plus précis. Veuillez noter que vous avoir souscrit un plan payant pour les appliquer."])},
  "customer.news.14-02-2023.caas.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outils de génération de contenu supplémentaires !"])},
  "customer.news.14-02-2023.upgrade.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-chevron-up"])},
  "customer.news.14-02-2023.upgrade.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous êtes un.e utilisateur.rice freemium, vous pouvez désormais passer à une autre version directement depuis la page Utilisation."])},
  "customer.news.14-02-2023.upgrade.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passer à une autre version depuis la plateforme !"])},
  "customer.news.16-09-2020.filters.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-filter"])},
  "customer.news.16-09-2020.filters.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci d'utiliser notre application si souvent. Avec l'utilisation accrue, nous réalisons l'importance de filters. Vous pouvez maintenant filtrer les projects par creation date et created by, entre autres. Nous améliorerons en permanence les fonctionnalités de filtrage et de recherche."])},
  "customer.news.16-09-2020.filters.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
  "customer.news.16-09-2020.messages.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-envelope"])},
  "customer.news.16-09-2020.messages.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous connaissons l'importance de pouvoir communiquer directement avec vos traducteurs sur des projets spécifiques. C’est pour cela que nous introduisons Messages. Les messages sont votre moyen de communiquer directement avec vos traducteurs. Pour créer un message à envoyer à votre traducteur, rendez-vous dans projet et choisissez tâche avec traducteur que vous souhaitez contacter."])},
  "customer.news.16-09-2020.messages.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
  "customer.news.16-09-2020.new_task_view.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-th-large"])},
  "customer.news.16-09-2020.new_task_view.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons optimisé votre expérience utilisateur grâce à New task view. Avec le lancement récent de review editor et ratings, nous avons réalisé qu'une nouvelle expérience utilisateur était nécessaire. Vous pourrez désormais voir toutes vos tâches d’un projet donné sous forme de cartes, telles que vous en avez l’habitude dans votre espace de travail."])},
  "customer.news.16-09-2020.new_task_view.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle vue Tâche"])},
  "customer.news.21-06-2023.caas.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-cog"])},
  "customer.news.21-06-2023.caas.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons remplacé notre ancien outil de génération de contenu par la nouvelle version ; il a donc été retiré de la version bêta. Découvrez-le depuis la barre latérale gauche."])},
  "customer.news.21-06-2023.caas.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle version de l’outil de génération de contenu !"])},
  "customer.news.21-06-2023.upgrade.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-chevron-up"])},
  "customer.news.21-06-2023.upgrade.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons rendu plusieurs outils et boutons plus visibles et plus conviviaux dans l'outil Editor ainsi que dans la String Library."])},
  "customer.news.21-06-2023.upgrade.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mises à niveau au sein de la plateforme !"])},
  "customer.news.21-07-2023.text_area.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file"])},
  "customer.news.21-07-2023.text_area.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons ajouté une zone de texte libre qui vous permet de joindre un commentaire ou des instructions lorsque vous créez un projet !"])},
  "customer.news.21-07-2023.text_area.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau champ de texte pour les projets"])},
  "customer.news.23-01-2023.caas.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-microchip"])},
  "customer.news.23-01-2023.caas.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous venons de publier notre tout nouvel outil Content Generation, qui vous permet de créer du contenu de haute qualité en quelques mots seulement ! Nous prenons actuellement en charge les e-mails, les blogs, les publicités et les descriptions de produits, et la liste va s’allonger à l'avenir !"])},
  "customer.news.23-01-2023.caas.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrez notre nouvel outil Content Generation !"])},
  "customer.news.23-01-2023.menu.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-bars"])},
  "customer.news.23-01-2023.menu.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons placé vos paramètres et votre section d'actualités dans le menu du profil en bas à gauche de l'application. Nous avons également déplacé la fonction Message vers la barre latérale gauche."])},
  "customer.news.23-01-2023.menu.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changements au menu !"])},
  "customer.news.23-09-2020.capacity_calculated_deadline.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-stopwatch"])},
  "customer.news.23-09-2020.capacity_calculated_deadline.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous calculons désormais votre délai en fonction de la capacité de vos traducteurs préférés. Cela signifie que nous prenons en considération les autres projets en cours de votre traducteur pour vous donner un délai plus précis."])},
  "customer.news.23-09-2020.capacity_calculated_deadline.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délai calculé en fonction de la capacité"])},
  "customer.news.23-09-2020.new_price_list_review.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file-invoice-dollar"])},
  "customer.news.23-09-2020.new_price_list_review.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour vous donner un aperçu clair des coûts liés à Translation and Review, nous avons ajouté une liste de prix pour Review. Vous pouvez toujours contacter votre gestionnaire de compte pour obtenir plus d'informations sur les prix et les options."])},
  "customer.news.23-09-2020.new_price_list_review.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle liste de prix pour Révision"])},
  "customer.news.23-09-2020.translation_and_review.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file-alt"])},
  "customer.news.23-09-2020.translation_and_review.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous introduisons un flux de travail supplémentaire pour optimiser la qualité de la traduction, Translation and Review. Vous pouvez maintenant choisir Translation and Review dans l’aperçu Project creation. Votre projet est maintenant à la fois traduit par votre traducteur préféré et révisé par l'un de vos autres traducteurs préférés. NB. Pour activer cette fonctionnalité, il vous faudra une équipe d'au moins 2 traducteurs dans la combinaison de langues concernée. Veuillez contacter votre gestionnaire de compte pour agrandir votre équipe."])},
  "customer.news.23-09-2020.translation_and_review.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduction et révision"])},
  "customer.news.24-11-2021.automation_workflow.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file-alt"])},
  "customer.news.24-11-2021.automation_workflow.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nous sommes ravis de vous présenter l'une de nos toutes dernières fonctionnalités : le ", _interpolate(_named("title")), ". Vous êtes désormais en mesure de concevoir votre ou vos propres flux de travail en fonction des équipes, des projets et des besoins spécifiques. Supprimez tout processus répétitif et assurez-vous que toutes les étapes opérationnelles sont aussi précises et efficaces que possible en créant des flux de travail automatisés. Une fois flux de travail workflow actif, il vous suffit de le choisir lors de la création de votre nouveau projet."])},
  "customer.news.24-11-2021.automation_workflow.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisation du flux de travail"])},
  "customer.news.24-11-2021.chat.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-comments"])},
  "customer.news.24-11-2021.chat.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour vous donner une meilleure visibility sur ce que vous écrivez, nous avons reviewed and implemented le design de la saisie de la discussion dans l'éditeur. Vous n'avez plus besoin de naviguer dans ce que vous avez écrit pour le lire ou le modifier, le champ de saisie affiche désormais votre contenu sous forme de lignes pour vous donner un aperçu complet. NB. La fonctionnalité de discussion n'est disponible que lorsqu'un ou plusieurs traducteurs de votre équipe sont impliqués dans le projet."])},
  "customer.news.24-11-2021.chat.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discuter"])},
  "customer.news.24-11-2021.history.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-history"])},
  "customer.news.24-11-2021.history.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'éditeur de révision d'EasyTranslate s’est enrichi d’une new functionality que vous aimerez: the history. Grâce à l'historique, gardez une trace de tous les changes apportés à un segment à un instant donné. restore previous versions ou analyser simplement le processus de révision, c’est à vous de choisir."])},
  "customer.news.24-11-2021.history.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique"])},
  "customer.news.24-11-2021.review_editor.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-folder"])},
  "customer.news.24-11-2021.review_editor.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est maintenant possible d'obtenir un overview du number of words and characters contenu par un segment. Cliquez simplement sur un segment et regardez en bas à gauche du tableau. En plus de cela, vous pouvez maintenant voir le correct ID number de chaque segment, même lorsque vous changez de page."])},
  "customer.news.24-11-2021.review_editor.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditeur de révision"])},
  "customer.news.24-11-2021.string_library.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file-alt"])},
  "customer.news.24-11-2021.string_library.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nous travaillons constamment à l'amélioration de votre expérience avec notre plateforme. Cela dit, nous avons publié une nouvelle fonctionnalité appelée ", _interpolate(_named("title")), " qui vous permet d’ integrate data à vos pages et publications avec moins de complexité et plus de flexibilité. Créez des individual libraries pour vos systèmes d'exploitation particuliers, ajoutez des données à votre bibliothèque en interne ou avec l'aide d'un traducteur et bénéficiez d'un déploiement plus rapide de votre contenu. Avec title, vous pouvez reuse vos données à tout moment sans qu’elles soient liées à des fichiers."])},
  "customer.news.24-11-2021.string_library.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothèque de chaînes"])},
  "customer.news.25-01-2022.string_library.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-database"])},
  "customer.news.25-01-2022.string_library.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous êtes déjà conquis par ", _interpolate(_named("title")), " ? Désormais, vous pouvez également traduire vos chaînes directement dans la bibliothèque. Une fois que vous avez configuré un flux de travail pour cela, sélectionnez vos langues et vous êtes prêt à commencer à envoyer des chaînes à traduire !"])},
  "customer.news.25-01-2022.string_library.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothèque de chaînes"])},
  "customer.news.25-01-2022.usage.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-dollar-sign"])},
  "customer.news.25-01-2022.usage.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://easytranslate.com/en/pricing/"])},
  "customer.news.25-01-2022.usage.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Avez-vous vu notre nouveau plan tarifaire ", _interpolate(_named("title")), " basé sur le volume et la tarification progressive ? Aller sur ", _interpolate(_named("link")), " pour en savoir plus, ou contactez-nous via le chat !"])},
  "customer.news.25-01-2022.usage.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisation"])},
  "customer.news.29-04-2021.search_within_the_editor_feature.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file-alt"])},
  "customer.news.29-04-2021.search_within_the_editor_feature.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos Translation Memory et Term Bank sont désormais accessibles et consultables dans l'outil Éditeur, afin que vous puissiez assurer la continuité en un rien de temps sans quitter la page. Utilisez le champ de recherche dans la phase de révision pour rechercher des mots et voir s'ils correspondent à votre mémoire de traduction et/ou à votre base de terminologie. Toutes les correspondances trouvées seront mises en surbrillance dans la section source de votre contenu. Besoin de surveiller le nombre de caractères ? Ces derniers sont désormais également visibles dans les champs source et cible."])},
  "customer.news.29-04-2021.search_within_the_editor_feature.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher dans la fonction Éditeur"])},
  "customer.news.29-04-2022.automated_content.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-microchip"])},
  "customer.news.29-04-2022.automated_content.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au lieu de passer du temps à écrire un texte produit de 300 mots, il vous suffit d’entrer quelques mots clés, et Content Generation créera le texte à votre place et le stockera dans des segments. Vous aurez également accès à l'Éditeur de Génération de contenu, dans lequel vous pourrez sélectionner les segments que vous aimez pour les modifier et les combiner dans le texte du produit final."])},
  "customer.news.29-04-2022.automated_content.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenu automatisé"])},
  "customer.news.29-04-2022.improved_workflows.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-angles-up"])},
  "customer.news.29-04-2022.improved_workflows.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendez vos processus de localisation encore plus personnalisables qu'auparavant avec la possibilité d’affecter un traducteur spécifique à une tâche de votre flux de travail. Vous pouvez sélectionner les traducteurs auxquels vous souhaitez confier vos tâches de traduction et de révision en fixant une 1<sup>re</sup>, 2<sup>e</sup> et 3<sup>e</sup> priorités. Grâce à cette amélioration, vous pouvez également couvrir plusieurs langues en moins de temps."])},
  "customer.news.29-04-2022.improved_workflows.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flux de travail améliorés"])},
  "customer.news.29-04-2022.qa.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-star"])},
  "customer.news.29-04-2022.qa.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre flux de travail devient moins manuel, car vous pouvez désormais rattacher une tâche de contrôle de qualité à votre tâche de traduction proprement dite. Vous pouvez sélectionner le traducteur évalué par le CQ ainsi que le traducteur exécutant la procédure. Personnalisez le CQ en fonction de vos priorités - vous décidez quels paramètres doivent peser le plus (langue, style, paramètres régionaux, précision ou terminologie)."])},
  "customer.news.29-04-2022.qa.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôle de qualité"])},
  "customer.news.29-04-2022.translation_reports.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-fichier"])},
  "customer.news.29-04-2022.translation_reports.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenez un aperçu complet du nombre de tâches qui ont été traitées incluant qui les a téléchargées, les flux de travail utilisés et bien plus encore"])},
  "customer.news.29-04-2022.translation_reports.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapports de traduction"])},
  "customer.news.29-07-2020.multiple_files_translation_projects.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file-alt"])},
  "customer.news.29-07-2020.multiple_files_translation_projects.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sommes maintenant prêts à gérer multiple-files projects, ce qui signifie que lorsque vous create a project, vous pouvez désormais attach multiple files. En faisant cela, vous créerez multiple projects at once. Et pour garder votre espace de travail organisé lors de cette opération, vous devrez affecter les nouveaux projets à un dossier existant ou simplement en créer un nouveau."])},
  "customer.news.29-07-2020.multiple_files_translation_projects.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer des projets de traduction avec plusieurs fichiers"])},
  "customer.news.29-07-2020.remove_users_from_your_team.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-user-friends"])},
  "customer.news.29-07-2020.remove_users_from_your_team.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous avez invité un de vos collègues par erreur ou que vous souhaitez simplement supprimer son accès, vous pouvez désormais facilement le remove de votre équipe. Cela signifie que l'utilisateur n'aura plus accès à votre espace de travail. Voir le remove button de Teams > Users."])},
  "customer.news.29-07-2020.remove_users_from_your_team.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer des utilisateurs de votre équipe"])},
  "customer.news.29-07-2020.translation_projects_into_folders.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-folder"])},
  "customer.news.29-07-2020.translation_projects_into_folders.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auparavant, vous pouviez seulement voir tous vos projets et en créer de nouveaux ; désormais, vous pouvez les regrouper intelligemment en folders dans votre compte workspace. C'est très simple ! Vous pouvez create a new folder en cliquant sur le bouton new folder à droite de la page my workspace, ou le faire en cas de besoin pendant project creation."])},
  "customer.news.29-07-2020.translation_projects_into_folders.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisez vos projets de traduction dans des dossiers"])},
  "customer.news.29-07-2020.users_can_decline_invitations.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-user-friends"])},
  "customer.news.29-07-2020.users_can_decline_invitations.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos utilisateurs invités peuvent maintenant refuser les invitations."])},
  "customer.news.29-07-2020.users_can_decline_invitations.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les utilisateurs peuvent refuser les invitations"])},
  "customer.news.31-08-2022.custom_mt.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-microchip"])},
  "customer.news.31-08-2022.custom_mt.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La TA personnalisée vous permettra d'avoir votre propre moteur de traduction automatique personnalisé, basé sur votre contenu dont nous disposons déjà. Nous réutilisons votre mémoire de traduction actuelle afin de rendre le moteur de traduction automatique plus intelligent et plus axé sur les thèmes de vos traductions existantes."])},
  "customer.news.31-08-2022.custom_mt.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TA personnalisée"])},
  "customer.onboarding.btn.login": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez déjà un compte ? Connexion ", _interpolate(_named("link"))])},
  "customer.onboarding.create_account.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte a été créé. Vous pouvez maintenant vous connecter pour accéder à votre compte."])},
  "customer.onboarding.loader.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On finit de mettre tous les points sur les i..."])},
  "customer.onboarding.loader_signup.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une seconde, deux secondes, trois secondes... ça vient !"])},
  "customer.overview.folders.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossiers"])},
  "customer.overview.projects.animation.title.new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On finit de mettre tous les points sur les i..."])},
  "customer.overview.projects.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projets"])},
  "customer.payment.usage.pricing_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultez nos tarifs"])},
  "customer.permission.approve-project-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approuver le prix du projet"])},
  "customer.permission.create-billing-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une méthode de facturation"])},
  "customer.permission.create-child-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un sous-compte"])},
  "customer.permission.create-content-generation-document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un document de génération de contenu"])},
  "customer.permission.create-content-generation-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un modèle de génération de contenu"])},
  "customer.permission.create-project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un projet"])},
  "customer.permission.create-project-folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un dossier"])},
  "customer.permission.create-task-revision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumettre la révision de la tâche"])},
  "customer.permission.create-team-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un membre de l'équipe"])},
  "customer.permission.create-team-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un rôle d'équipe personnalisé"])},
  "customer.permission.create-translation-assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer des ressources de traduction"])},
  "customer.permission.create-translation-assets.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les utilisateurs peuvent créer des ressources de traduction"])},
  "customer.permission.create-translation-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des freelances"])},
  "customer.permission.create-webhook-endpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un point de terminaison Webhook"])},
  "customer.permission.create-workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un workflow"])},
  "customer.permission.create-workflow.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les utilisateurs peuvent créer un flux de travail"])},
  "customer.permission.delete-billing-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la méthode de facturation"])},
  "customer.permission.delete-child-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le sous-compte"])},
  "customer.permission.delete-content-generation-document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le document de génération de contenu"])},
  "customer.permission.delete-content-generation-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le modèle de génération de contenu"])},
  "customer.permission.delete-project-folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le dossier"])},
  "customer.permission.delete-team-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer un membre de l'équipe"])},
  "customer.permission.delete-team-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le rôle d'équipe personnalisé"])},
  "customer.permission.delete-translation-assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer les ressources de traduction"])},
  "customer.permission.delete-translation-assets.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les utilisateurs peuvent supprimer les ressources de traduction"])},
  "customer.permission.delete-webhook-endpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le point de terminaison du Webhook"])},
  "customer.permission.delete-workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le flux de travail"])},
  "customer.permission.delete-workflow.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les utilisateurs peuvent supprimer un flux de travail"])},
  "customer.permission.download-invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger la facture"])},
  "customer.permission.download-project-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger les fichiers du projet"])},
  "customer.permission.join-channel-chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoignez le canal de discussion"])},
  "customer.permission.read-billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les détails de facturation"])},
  "customer.permission.read-billing-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les méthodes de facturation"])},
  "customer.permission.read-child-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les sous-comptes"])},
  "customer.permission.read-content-generation-document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les documents de génération de contenu"])},
  "customer.permission.read-content-generation-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les modèles de génération de contenu"])},
  "customer.permission.read-oauth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les informations d'identification OAuth"])},
  "customer.permission.read-payin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les informations sur les prix"])},
  "customer.permission.read-price-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir la liste de prix"])},
  "customer.permission.read-project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les détails du projet"])},
  "customer.permission.read-project-folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les dossiers"])},
  "customer.permission.read-task-revision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les révisions des tâches"])},
  "customer.permission.read-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les informations du compte"])},
  "customer.permission.read-team-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les membres de l'équipe"])},
  "customer.permission.read-team-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les rôles et les autorisations des utilisateurs"])},
  "customer.permission.read-translation-assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les ressources de traduction"])},
  "customer.permission.read-translation-assets.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les utilisateurs peuvent voir la page des ressources de traduction"])},
  "customer.permission.read-translation-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les freelances ajoutés"])},
  "customer.permission.read-translation-team-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les tarifs des freelances"])},
  "customer.permission.read-webhook-endpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher le point de terminaison du Webhook"])},
  "customer.permission.read-workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher le flux de travail"])},
  "customer.permission.read-workflow.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les utilisateurs peuvent voir les flux de travail"])},
  "customer.permission.update-automation-price-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite de prix de l'automatisation de la mise à jour"])},
  "customer.permission.update-billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour les détails de facturation"])},
  "customer.permission.update-billing-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour la méthode de facturation"])},
  "customer.permission.update-child-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour le sous-compte"])},
  "customer.permission.update-content-generation-document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour le document de génération de contenu"])},
  "customer.permission.update-content-generation-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour le modèle de génération de contenu"])},
  "customer.permission.update-project-folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour le dossier"])},
  "customer.permission.update-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour les informations du compte"])},
  "customer.permission.update-team-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour le membre de l'équipe"])},
  "customer.permission.update-team-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour le rôle de l'équipe personnalisée"])},
  "customer.permission.update-translation-assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour les ressources de traduction"])},
  "customer.permission.update-translation-assets.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les utilisateurs peuvent modifier/changer les ressources de traduction"])},
  "customer.permission.update-translation-team-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour des tarifs pour les freelances"])},
  "customer.permission.update-user-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour les rôles et les autorisations des utilisateurs"])},
  "customer.permission.update-webhook-endpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour le point de terminaison du Webhook"])},
  "customer.permission.update-workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour le flux de travail"])},
  "customer.permission.update-workflow-qa-threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour le seuil d'assurance qualité du flux de travail"])},
  "customer.permission.update-workflow-qa-threshold.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les utilisateurs peuvent modifier/changer le seuil d'assurance qualité pour un flux de travail"])},
  "customer.permission.update-workflow.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les utilisateurs peuvent modifier/changer un flux de travail"])},
  "customer.phrase_connector.active_connetor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecteur actif"])},
  "customer.phrase_connector.connection_unsuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de connexion"])},
  "customer.phrase_connector.header_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégrez votre compte EasyTranslate à votre compte Phrase"])},
  "customer.phrase_connector.header_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégrer avec Phrase"])},
  "customer.phrase_connector.language_pairing.create_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la création de paires de langues, merci de réessayer"])},
  "customer.phrase_connector.language_pairing.create_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paires de langues créées avec succès"])},
  "customer.phrase_connector.language_pairing.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer des paires de langues"])},
  "customer.phrase_connector.language_pairing.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paires de langues créées avec succès"])},
  "customer.phrase_connector.language_pairing.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paires de langues"])},
  "customer.phrase_connector.language_pairing.update_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la mise à jour des paires de langues. Merci de réessayer"])},
  "customer.phrase_connector.language_pairing.update_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paires de langues mises à jour avec succès"])},
  "customer.phrase_connector.step_language_pairing.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N'oubliez pas de cliquer sur « Sauvegarder » lorsque vous avez effectué des modifications ou des suppressions pour les paires de langues"])},
  "customer.phrase_connector.step_language_pairing.phrase_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de phrase"])},
  "customer.phrase_connector.step_language_pairing.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurer les paires de langues"])},
  "customer.phrase_connector.step_one.hint_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un lien vers Phrase"])},
  "customer.phrase_connector.step_one.hint_1_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiez et collez le lien ci-dessous et ajoutez-le à Phrase"])},
  "customer.phrase_connector.step_one.hint_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un ID client"])},
  "customer.phrase_connector.step_one.hint_2_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre ID client de Phrase"])},
  "customer.phrase_connector.step_one.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez votre compte Phrase à votre compte EasyTranslate"])},
  "customer.phrase_connector.step_one.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer la connexion à Phrase"])},
  "customer.phrase_connector.step_three.click_below": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur le bouton ci-dessous pour démarrer le processus de vérification"])},
  "customer.phrase_connector.step_three.go_to_phrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir Phrase"])},
  "customer.phrase_connector.step_three.provider_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un fournisseur"])},
  "customer.phrase_connector.step_three.provider_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur créé"])},
  "customer.phrase_connector.step_three.success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez désormais créer votre toute première tâche via Phrase"])},
  "customer.phrase_connector.step_three.success_message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurez-vous d'attribuer ET_admin comme traducteur et d'appliquer le bon workflow"])},
  "customer.phrase_connector.step_three.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez votre flux"])},
  "customer.phrase_connector.step_three.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez votre flux"])},
  "customer.phrase_connector.step_three.token_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez votre compte Phrase à votre compte EasyTranslate"])},
  "customer.phrase_connector.step_three.token_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeton d'accès créé"])},
  "customer.phrase_connector.step_three.webhook_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un webhook"])},
  "customer.phrase_connector.step_three.webhook_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook créé"])},
  "customer.phrase_connector.step_three.workflow_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez votre workflow et activez-le"])},
  "customer.phrase_connector.step_three.workflow_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflow créé"])},
  "customer.phrase_connector.step_two.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez votre workflow"])},
  "customer.phrase_connector.step_two.text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez l'ID du workflow au champ « Notes » dans Phrase"])},
  "customer.phrase_connector.step_two.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un workflow"])},
  "customer.placeholder.search_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un nom/identifiant de projet ou un nom de dossier"])},
  "customer.price_limit.about.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déterminer une limite de prix sur le processus automatisé. Tous les projets avec un prix plus élevé devront recevoir votre approbation avant d'être réalisés."])},
  "customer.price_limit.about.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définissez votre limite de prix"])},
  "customer.price_limit.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixez une limite de prix pour votre solution de traduction automatisée."])},
  "customer.privacy.consent.marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["marketing"])},
  "customer.privacy.consent.privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])},
  "customer.privacy.consent.product_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["catégories de produits"])},
  "customer.privacy.consent.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Oui, je consens à recevoir le ", _interpolate(_named("marketing")), " par e-mail de la part d'EasyTranslate A/S concernant leur ", _interpolate(_named("product_categories")), ". EasyTranslate traite mes données personnelles conformément à sa ", _interpolate(_named("privacy_policy")), ". Je peux retirer mon consentement à tout moment ", _interpolate(_named("here")), "."])},
  "customer.privacy.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions générales"])},
  "customer.progress.add_billing_method.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une méthode de facturation"])},
  "customer.progress.add_review_supplier.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un réviseur (facultatif)"])},
  "customer.progress.add_translation_supplier.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un traducteur (facultatif)"])},
  "customer.progress.automation_flows.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisation des flux de travail"])},
  "customer.progress.automation_flows.steps.create_account_workflow.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un flux de travail personnalisé (facultatif)"])},
  "customer.progress.create_account_workflow.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un flux de travail personnalisé (facultatif)"])},
  "customer.progress.create_review_project.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer votre premier projet de traduction et de révision (facultatif)"])},
  "customer.progress.create_translation_project.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez votre premier projet de traduction"])},
  "customer.progress.enable_wallet.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activez votre portefeuille"])},
  "customer.progress.first_project.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre premier projet"])},
  "customer.progress.getting_started.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se lancer"])},
  "customer.progress.getting_started.steps.add_billing_method.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une méthode de facturation"])},
  "customer.progress.getting_started.steps.enable_wallet.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activez votre portefeuille"])},
  "customer.progress.getting_started.steps.update_billing_details.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour vos informations de facturation"])},
  "customer.progress.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Découvrez les actions que vous devez entreprendre avant de vous lancer. Toutes les étapes à l'intérieur de ", _interpolate(_named("name")), " sont obligatoires"])},
  "customer.progress.update_billing_details.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour vos informations de facturation"])},
  "customer.progress.use_account_workflow.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser un flux de travail personnalisé (facultatif)"])},
  "customer.project.approve_project.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez approuver le prix de votre projet"])},
  "customer.project.button.download_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout télécharger"])},
  "customer.project.modal.name_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce nom de projet existe déjà"])},
  "customer.project.skipped_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulé : Non exécuté car les critères n'étaient pas respectés"])},
  "customer.project.zip.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargez le fichier zip ou créez un nouveau fichier de préparation"])},
  "customer.project.zip.email_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un e-mail vous sera envoyé lorsque le fichier zip sera prêt à être téléchargé"])},
  "customer.project.zip.prepare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur le bouton Préparer le fichier pour lancer le processus"])},
  "customer.project.zip.prepare_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préparer le dossier"])},
  "customer.project.zip.prepare_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préparation du fichier"])},
  "customer.project.zip.preparing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préparation en cours"])},
  "customer.project.zip.re_prepare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez préparer de nouveau le fichier dans"])},
  "customer.project_filters.agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent"])},
  "customer.project_filters.created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])},
  "customer.project_filters.customer_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])},
  "customer.project_filters.preferred_deadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échéance préférée"])},
  "customer.project_filters.status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
  "customer.project_modal.preferred_deadline.set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour fixer une échéance préférée"])},
  "customer.project_modal.preferred_deadline.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La définition d'une échéance préférée remplace la logique d'affectation dans LaaS en ignorant la disponibilité de votre traducteur. Cela signifie que votre traducteur peut ne pas être en mesure de livrer à la date souhaitée ; votre tâche pourrait ainsi être attribuée à un autre traducteur de votre équipe. Il n'y a toutefois aucune garantie qu'un membre de votre équipe aura la capacité de livrer à la date souhaitée. En cas de doute, veuillez toujours demander à votre traducteur en utilisant la fonction de message."])},
  "customer.project_modal.preferred_deadline_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez noter que si vous commandez une traduction + relecture, votre échéance préférée ne sera visible que par le traducteur et non par le relecteur. Nous vous suggérons d'en informer votre traducteur pour qu’il en tienne compte."])},
  "customer.project_modal.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez noter que nous n'acceptons que les clients professionnels"])},
  "customer.project_modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez votre projet ici"])},
  "customer.project_modal.video_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutoriel vidéo sur la création d'un projet"])},
  "customer.projects.empty.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez aucun projet en cours."])},
  "customer.rate.modal.recommend_freelancer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommanderiez-vous votre freelance ?"])},
  "customer.reassign_task.customer_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réattribuer le ou la client.e"])},
  "customer.reassign_task.freelancer_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réattribuer un ou une freelance"])},
  "customer.reassign_task.not_assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["freelance non attribué.e"])},
  "customer.reports.btn.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le rapport"])},
  "customer.reports.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette exportation comprendra des informations concernant vos projets, telles que le nom d'utilisateur du créateur du projet, le nombre de mots, le flux de travail utilisé ainsi que l'état du projet, le type de tâche, etc."])},
  "customer.reports.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargez vos rapports de traduction personnels"])},
  "customer.roles.create.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau rôle"])},
  "customer.roles.empty.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun rôle personnalisé n'a été trouvé"])},
  "customer.roles.empty.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôles personnalisés introuvables"])},
  "customer.signup.success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription réussie. Vous allez maintenant être redirigé vers la page de connexion."])},
  "customer.sub_nav.account_info.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information sur le compte"])},
  "customer.sub_nav.account_structor.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Structure de compte"])},
  "customer.sub_nav.api.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API"])},
  "customer.sub_nav.balance.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde"])},
  "customer.sub_nav.billing.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation"])},
  "customer.sub_nav.caas.save_your_docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrez vos documents"])},
  "customer.sub_nav.finances.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finances"])},
  "customer.sub_nav.folders.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossiers"])},
  "customer.sub_nav.impersonate.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter à la place de l’utilisateur"])},
  "customer.sub_nav.my_usage.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon utilisation"])},
  "customer.sub_nav.price_limit.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite de prix"])},
  "customer.sub_nav.privacy.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confidentialité & consentement"])},
  "customer.sub_nav.profile.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "customer.sub_nav.projects.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projets"])},
  "customer.sub_nav.reports.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapports de traduction"])},
  "customer.sub_nav.revision_price.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix de révision"])},
  "customer.sub_nav.roles_permission.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôles & autorisations"])},
  "customer.sub_nav.subaccounts_usage.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisation des sous-comptes"])},
  "customer.sub_nav.total_usage.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisation totale de l'entreprise"])},
  "customer.sub_nav.translation_price.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix de la traduction"])},
  "customer.sub_nav.usage.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisation"])},
  "customer.sub_nav.users_permission.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs & autorisations"])},
  "customer.sub_nav.wallet.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portefeuille"])},
  "customer.subscription.update.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement mis à jour"])},
  "customer.template.modal.name_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce nom de modèle existe déjà"])},
  "customer.terms_conditions.modal.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons mis à jour nos conditions générales. Avant de pouvoir continuer à utiliser notre plateforme, vous devez accepter nos nouvelles conditions générales."])},
  "customer.terms_conditions.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelles conditions générales"])},
  "customer.toast.error.caas_invalid_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot clé invalide. Veuillez respecter le format décrit dans l'infobulle"])},
  "customer.toast.error.caas_no_special_chars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les caractères spéciaux ne sont pas autorisés dans les noms clés"])},
  "customer.toast.error.caas_no_whitespaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les espaces ne sont pas autorisés dans les noms clés"])},
  "customer.toast.error.connect_stripe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s’est produite lors de la tentative de connexion à Stripe"])},
  "customer.toast.error.folder_not_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite : votre dossier n'a pas pu être supprimé"])},
  "customer.toast.error.invalid_account": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aucun compte avec le nom indiqué « ", _interpolate(_named("account")), " » n’a été trouvé"])},
  "customer.toast.error.invalid_account_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de compte invalide"])},
  "customer.toast.error.min_five_keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer un minimum de 5 mots clés avant d'enregistrer"])},
  "customer.toast.error.prefill_account_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s’est produite et nous n'avons pas pu pré-remplir les informations de votre compte"])},
  "customer.toast.error.rating_min_one_star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez sélectionner au moins un départ"])},
  "customer.toast.error.register_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s’est produite et nous n'avons pas pu vous connecter"])},
  "customer.toast.error.registration_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite lorsque nous avons essayé de vous inscrire"])},
  "customer.toast.error.something_went_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite, veuillez réessayer"])},
  "customer.toast.error.sub_tier_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre niveau d'abonnement a été entièrement utilisé"])},
  "customer.toast.error.team_no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne disposez pas des autorisations nécessaires pour effectuer cette action"])},
  "customer.toast.error.update_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite lors de la tentative de mise à jour de votre mode de paiement"])},
  "customer.toast.error.user_stopped_action_close_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite : votre demande a été interrompue car la fenêtre a été fermée pendant son traitement."])},
  "customer.toast.success.accept_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez accepté le projet"])},
  "customer.toast.success.card_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre carte par défaut a été mise à jour"])},
  "customer.toast.success.confirm_segments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les segments ont été confirmés"])},
  "customer.toast.success.create_project_basic": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le projet « ", _interpolate(_named("project")), " » a été créé."])},
  "customer.toast.success.create_project_unnamed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le projet a été créé. Vous retrouverez votre projet dans votre ", _interpolate(_named("workspaceLink")), "."])},
  "customer.toast.success.create_project_unnamed_basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le projet a été créé."])},
  "customer.toast.success.flow_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre flux d'automatisation a été supprimé avec succès"])},
  "customer.toast.success.folder_create": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le dossier « ", _interpolate(_named("folder")), " » a été créé"])},
  "customer.toast.success.inactive_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre portefeuille est maintenant inactif"])},
  "customer.toast.success.invitation_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'invitation a été annulée et l'utilisateur a été retiré"])},
  "customer.toast.success.library_add_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["« ", _interpolate(_named("language")), " » a été ajoutée à votre bibliothèque"])},
  "customer.toast.success.library_automation_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'automatisation de cette bibliothèque a été activée et est maintenant en cours d'exécution"])},
  "customer.toast.success.library_create_key": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La clé « ", _interpolate(_named("key")), " » a été créée"])},
  "customer.toast.success.library_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La bibliothèque « ", _interpolate(_named("name")), " » a été créée"])},
  "customer.toast.success.library_delete_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les clés ont été supprimées"])},
  "customer.toast.success.library_remove": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La bibliothèque « ", _interpolate(_named("library")), " » a été supprimée avec succès"])},
  "customer.toast.success.library_remove_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["« ", _interpolate(_named("language")), " » a été retiré(e) de votre bibliothèque"])},
  "customer.toast.success.library_save_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les modifications apportées à votre chaîne ont été enregistrées"])},
  "customer.toast.success.library_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vos modifications à la bibliothèque « ", _interpolate(_named("library")), " » ont été enregistrés"])},
  "customer.toast.success.rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci, votre évaluation a été soumise"])},
  "customer.toast.success.remove_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mode de paiement a été supprimé avec succès"])},
  "customer.toast.success.remove_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utilisateur a été retiré avec succès"])},
  "customer.toast.success.request_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande est en cours de traitement et un nouveau linguiste sera prochainement affecté"])},
  "customer.toast.success.segments_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les segments ont été confirmés"])},
  "customer.toast.success.segments_unconfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La confirmation de tous les segments a été annulée"])},
  "customer.toast.success.update_billing_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos informations de facturation ont été mises à jour"])},
  "customer.toast.success.update_price_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre limite de prix a été mise à jour"])},
  "customer.toast.success.update_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les prix de vos prestations ont été mis à jour"])},
  "customer.toast.success.update_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les informations de votre profil ont été mises à jour"])},
  "customer.toast.success.update_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le contrôle qualité a été mis à jour avec succès"])},
  "customer.toast.success.update_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les informations de votre portefeuille ont été mises à jour"])},
  "customer.toast.success.upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez été surclassé"])},
  "customer.toast.warning.existing_team_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons trouvé votre équipe. Vous serez averti lorsque l'administrateur aura accepté votre demande de rejoindre l'équipe."])},
  "customer.toast.warning.incomplete_billing_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avant de pouvoir mettre à jour, vous devez compléter vos informations de facturation"])},
  "customer.toasts.error.finish_revision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre révision n'a pas pu être marquée comme terminée"])},
  "customer.tooltip.string_library.product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez la bibliothèque de produits si vous souhaitez générer du contenu"])},
  "customer.tooltip.string_library.translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez la bibliothèque de traduction que vous souhaitez faire traduire"])},
  "customer.tooltip.string_library.trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si les chaînes de votre bibliothèque non traduites dépassent le pourcentage sélectionné, nous déclencherons ce flux automatiquement"])},
  "customer.tooltip.translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez noter que la tâche sera attribuée au premier traducteur que vous avez sélectionné. Si ce traducteur refuse, elle sera attribuée aux autres traducteurs que vous avez sélectionnés, par ordre chronologique."])},
  "customer.translation_assets.custom_mt.bleu_legend.10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presque inutile"])},
  "customer.translation_assets.custom_mt.bleu_legend.19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Difficile de saisir l'essentiel"])},
  "customer.translation_assets.custom_mt.bleu_legend.29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'essentiel est clair, mais comporte d'importantes erreurs grammaticales"])},
  "customer.translation_assets.custom_mt.bleu_legend.39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traductions compréhensibles à bonnes"])},
  "customer.translation_assets.custom_mt.bleu_legend.49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traductions de haute qualité"])},
  "customer.translation_assets.custom_mt.bleu_legend.59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traductions de très haute qualité, adéquates et fluides"])},
  "customer.translation_assets.custom_mt.bleu_legend.60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualité souvent meilleure que la traduction humaine"])},
  "customer.translation_assets.custom_mt.bleu_legend.interpretation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interprétation"])},
  "customer.translation_assets.custom_mt.cancel.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui, annuler la formation"])},
  "customer.translation_assets.custom_mt.cancel.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Êtes-vous sûr de vouloir annuler la formation du modèle ", _interpolate(_named("name")), " ?"])},
  "customer.translation_assets.custom_mt.cancel.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler la formation"])},
  "customer.translation_assets.custom_mt.cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
  "customer.translation_assets.custom_mt.modal_instructions.requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez utiliser cette fonctionnalité que si votre compte répond aux exigences suivantes :"])},
  "customer.translation_assets.custom_mt.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MT personnalisée"])},
  "customer.translation_assets.custom_mt.training_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur l’entraînement"])},
  "customer.translation_assets.custom_mt.training_pairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paires d’entraînement"])},
  "customer.translation_assets.custom_mt.training_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps d’entraînement"])},
  "customer.translation_assets.custom_mt.view.info_block.requirement_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum 1000 segments/chaîne"])},
  "customer.translation_assets.custom_mt.view.info_block.requirement_three": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Langues prises en charge - cliquez sur ", _interpolate(_named("here")), " pour voir la liste"])},
  "customer.translation_assets.custom_mt.view.info_block.requirement_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum 200 mots par segment/chaîne"])},
  "customer.translation_assets.custom_mt.view.info_block.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre MT stockée doit répondre à ces exigences"])},
  "customer.translation_assets.custom_mt.view_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez votre modèle d’entraînement de la MT"])},
  "customer.translation_assets.glossary.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glossaire"])},
  "customer.translation_assets.translation_memory.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mémoire de traduction"])},
  "customer.translation_price.about.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez ici suivre la structure tarifaire convenue et le programme de remise TM (mémoire de traduction)."])},
  "customer.translation_price.about.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La transparence est essentielle pour nous."])},
  "customer.translation_price.discount.match_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondance à 0-49 %"])},
  "customer.translation_price.discount.match_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondance à 100 %"])},
  "customer.translation_price.discount.match_101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondance à 101 %"])},
  "customer.translation_price.discount.match_50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondance à 50-74 %"])},
  "customer.translation_price.discount.match_75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondance à 74-84 %"])},
  "customer.translation_price.discount.match_85": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondance à 85-94 %"])},
  "customer.translation_price.discount.match_95": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondance à 95-99 %"])},
  "customer.translation_price.discount.repetitions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répétitions"])},
  "customer.translation_price.discount.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabais de traduction"])},
  "customer.translation_price.price_list.btn.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter nouveau"])},
  "customer.translation_price.price_list.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle entrée de prix"])},
  "customer.translation_price.price_list.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste de prix"])},
  "customer.translator.empty.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous n'avons trouvé aucun traducteur dans votre équipe"])},
  "customer.translator.empty.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de traducteur"])},
  "customer.translator.modal_service.change_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer le prix"])},
  "customer.translator.modal_service.delete.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sachez que la prestation sera définitivement supprimée et que cette action est irréversible."])},
  "customer.translator.modal_service.delete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les paires de langues à supprimer de votre compte"])},
  "customer.translator.modal_service.delete.title_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer les paires de langues suivantes ?"])},
  "customer.translator.modal_service.delete_lang_pair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la paire de langues"])},
  "customer.translator.modal_service.remove_translator": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Etes-vous sûr de vouloir supprimer ", _interpolate(_named("name")), " de votre compte ?"])},
  "customer.translator.modal_service.service_deleted.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La paire de langues sélectionnée a été supprimée de vos prestations"])},
  "customer.translator.modal_service.service_deleted.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prestation supprimée"])},
  "customer.translator.translator_remove.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " a été retiré(e) avec succès de vos traducteurs"])},
  "customer.translator.translator_remove.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traducteur retiré"])},
  "customer.translator_team.loader.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sommes en train d’analyser votre document et recherchons la ou les meilleures correspondances pour vous"])},
  "customer.translator_team.loader.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouver un traducteur"])},
  "customer.upgarde_sub.word_credits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["crédits de mots"])},
  "customer.upgarde_sub.word_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["limite de mots"])},
  "customer.upgrade_sub.12months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 mois"])},
  "customer.upgrade_sub.1month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 mois"])},
  "customer.upgrade_sub.billed_annually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation annuelle (économisez 50 %)"])},
  "customer.upgrade_sub.billed_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation mensuelle"])},
  "customer.upgrade_sub.contact_sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez le service commercial"])},
  "customer.upgrade_sub.contact_sus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])},
  "customer.upgrade_sub.current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre forfait actuel"])},
  "customer.upgrade_sub.custom_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix personnalisé"])},
  "customer.upgrade_sub.downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passer au niveau inférieur"])},
  "customer.upgrade_sub.enterprise_banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des possibilités infinies"])},
  "customer.upgrade_sub.feature.caas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres avancés de génération de contenu"])},
  "customer.upgrade_sub.feature.custom_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité de mots personnalisée"])},
  "customer.upgrade_sub.feature.customer_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistance client.e prioritaire"])},
  "customer.upgrade_sub.feature.optimisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séance d'optimisation personnelle"])},
  "customer.upgrade_sub.feature.technical_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistance technique prioritaire"])},
  "customer.upgrade_sub.feature.translator_recruitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recrutement de traducteur.rice.s sur mesure"])},
  "customer.upgrade_sub.finish_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finaliser le paiement"])},
  "customer.upgrade_sub.free_trial.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez votre essai gratuit maintenant et testez notre plateforme"])},
  "customer.upgrade_sub.free_trial_anim.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*sur les forfaits annuels"])},
  "customer.upgrade_sub.free_trial_anim.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essayez maintenant, décidez plus tard"])},
  "customer.upgrade_sub.free_trial_anim.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essai gratuit de 7 jours !"])},
  "customer.upgrade_sub.month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mois"])},
  "customer.upgrade_sub.payment.cancel_anytime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulez à tout moment"])},
  "customer.upgrade_sub.payment.free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 jours d'essai gratuit"])},
  "customer.upgrade_sub.paymentInfo_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir vos informations de paiement pour commencer"])},
  "customer.upgrade_sub.period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Période : de ", _interpolate(_named("fromDate")), " à ", _interpolate(_named("toDate"))])},
  "customer.upgrade_sub.plan_get_started": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Commencez avec le plan ", _interpolate(_named("plan"))])},
  "customer.upgrade_sub.scaleup_banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La formule la plus populaire"])},
  "customer.upgrade_sub.start_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez l'essai gratuit"])},
  "customer.upgrade_sub.startup_banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éliminez les limites"])},
  "customer.upgrade_sub.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez un mode de paiement pour passer au niveau supérieur maintenant"])},
  "customer.upgrade_sub.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre projet dépasse les ", _interpolate(_named("text")), " de votre plan actuel"])},
  "customer.upgrade_sub.title.caas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passez votre compte au niveau supérieur pour déverrouiller cette fonctionnalité"])},
  "customer.upgrade_sub.title.usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouvez un plan qui correspond à vos besoins"])},
  "customer.upgrade_sub.trial.first_bullet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos 7 premiers jours sont gratuits, annulez à tout moment dans les 7 jours"])},
  "customer.upgrade_sub.trial.second_bullet": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre premier paiement sera débité le ", _interpolate(_named("date"))])},
  "customer.upgrade_sub.trial.third_bullet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous enverrons un rappel 1 jour avant la fin de votre essai"])},
  "customer.upgrade_sub.upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passer au niveau supérieur"])},
  "customer.usage.feature.tooltip.automation_workflows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cela représente les flux de travail d’automatisation créés dans votre plan."])},
  "customer.usage.feature.tooltip.string_library_keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cela représente l'utilisation des clés de la bibliothèque de chaînes pour la traduction et le stockage de contenu."])},
  "customer.usage.feature.tooltip.supplier_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cela représente le nombre de traducteurs que vous avez ajoutés à votre équipe dans le cadre de votre forfait."])},
  "customer.usage.feature.tooltip.supplier_translated_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cela représente l'utilisation des crédits de mots lorsque vous traduisez du contenu sur la plateforme. L'utilisation est catégorisée par type de traduction."])},
  "customer.usage.legend.automation_workflows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflows restants"])},
  "customer.usage.legend.string_library_keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clés restantes de la bibliothèque de chaînes"])},
  "customer.usage.legend.supplier_translated_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédits restants"])},
  "customer.usage.legend_used.automation_workflows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flux de travail créés"])},
  "customer.usage.legend_used.string_library_keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clés de la bibliothèque de chaînes créées"])},
  "customer.usage.legend_used.supplier_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout de traducteurs"])},
  "customer.usage.middle_text.automation_workflows": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " workflows créés"])},
  "customer.usage.middle_text.string_library_keys": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Clés de bibliothèque de chaînes ", _interpolate(_named("amount"))])},
  "customer.usage.middle_text.supplier_team": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " traducteurs"])},
  "customer.usage.middle_text.supplier_translated_words": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " de crédits de mots utilisés"])},
  "customer.usage.subaccount.account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'équipe"])},
  "customer.usage.subaccount.compare_teams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparer les équipes"])},
  "customer.usage.subaccount.compare_teams.modal_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Comparez ", _interpolate(_named("teamA")), " et ", _interpolate(_named("teamB"))])},
  "customer.usage.subaccount.compare_teams_limit_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un maximum de deux équipes peuvent être comparées à la fois"])},
  "customer.usage.subaccount.meta.content_generation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Génération de contenu"])},
  "customer.usage.subaccount.meta.machine_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TA &amp; TA personnalisée"])},
  "customer.usage.subaccount.meta.translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduction humaine"])},
  "customer.usage.subaccount.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vue détaillée de l'utilisation pour chaque sous-compte"])},
  "customer.usage.subaccount.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisation des sous-comptes"])},
  "customer.usage.subaccount.view_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les détails"])},
  "customer.usage.tooltip.automation_workflows.remaining": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " Workflows restants\n<br><br>\n", _interpolate(_named("percentage")), "% restant du total des Workflows selon votre plan"])},
  "customer.usage.tooltip.automation_workflows.used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " Workflows créés\n<br><br>\n", _interpolate(_named("percentage")), "% Workflows créés sur le montant total selon votre plan"])},
  "customer.usage.tooltip.string_library_keys.remaining": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " Clés de bibliothèque de chaînes restantes\n<br><br>\n", _interpolate(_named("percentage")), "% restant du total des clés de bibliothèque de chaînes selon votre forfait"])},
  "customer.usage.tooltip.string_library_keys.used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " Clés de bibliothèque de chaînes créées\n<br><br>\n", _interpolate(_named("percentage")), "% Clés de bibliothèque de chaînes créées sur le montant total selon votre forfait"])},
  "customer.usage.tooltip.supplier_team.remaining": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " Traducteurs restants à ajouter à votre équipe\n<br><br>\n", _interpolate(_named("percentage")), "% restant du total des traducteurs selon votre plan"])},
  "customer.usage.tooltip.supplier_team.used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " Traducteurs ajoutés\n<br><br>\n", _interpolate(_named("percentage")), "% de traducteurs ajoutés sur le montant total selon votre forfait"])},
  "customer.usage.tooltip.word_credit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<b>", _interpolate(_named("usage")), "</b> Crédits Word utilisés <b>", _interpolate(_named("type")), "</b> \n<br><br>\n<b>", _interpolate(_named("percentage")), "%</b> sont utilisés pour ", _interpolate(_named("type")), " sur le montant total de Crédits Word \n<br><br>\n<b>", _interpolate(_named("cost")), "$</b> par Crédit Word"])},
  "customer.usage.tooltip.word_credit.remaining": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<b>", _interpolate(_named("amount")), "</b> <b>Crédits de mots restants</b>\n<br ><br>\n<b>", _interpolate(_named("percentage")), "%</b> manquants sur le total des crédits de mots selon votre forfait"])},
  "customer.usage.tooltip.word_credit_compare": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<b>", _interpolate(_named("usage")), "</b> Les crédits de mots <b>", _interpolate(_named("type")), "</b> utilisés\n<br><br>\n<b>", _interpolate(_named("percentage")), "%</b> sont utilisés pour ", _interpolate(_named("type")), "\n<br><br>\n<b>", _interpolate(_named("cost")), "</b> crédits de mots par mot"])},
  "customer.usage.total_usage_bar.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Utilisation totale de votre forfait ", _interpolate(_named("plan")), " pour :"])},
  "customer.user_permissions.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accorder l'accès aux comptes"])},
  "customer.users_permissions.account.cancel_invite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous êtes sur le point d'annuler l'invitation pour ", _interpolate(_named("name")), ". Êtes-vous sûr de vouloir effectuer cette action ?"])},
  "customer.users_permissions.account.remove_user": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous êtes sur le point de retirer ", _interpolate(_named("name")), " de votre compte d'équipe. Êtes-vous sûr de vouloir poursuivre cette action ?"])},
  "customer.users_permissions.btn.invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter un nouvel utilisateur"])},
  "customer.users_permissions.btn.remove_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer l'utilisateur du compte ?"])},
  "customer.users_permissions.modal.btn.send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer une invitation"])},
  "customer.users_permissions.modal.edit.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas modifier vos propres rôles et autorisations"])},
  "customer.users_permissions.modal.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter l'utilisateur par e-mail"])},
  "customer.users_permissions.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitez des utilisateurs dans votre équipe"])},
  "customer.users_permissions.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes utilisateurs"])},
  "customer.users_permissions.title_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelles demandes d'utilisateurs"])},
  "customer.voucher.added_cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos cartes ajoutées"])},
  "customer.voucher.no_permissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Oups ! Vous n'êtes pas autorisé à modifier les détails de facturation. Contactez l'administrateur de votre équipe ", _interpolate(_named("admin")), " pour obtenir les autorisations correctes."])},
  "customer.voucher.overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])},
  "customer.voucher.paymet_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos informations de paiement"])},
  "customer.voucher.project_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix du projet"])},
  "customer.voucher.redeem_voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser le bon"])},
  "customer.voucher.saving_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mode de paiement sera enregistré sur votre compte pour de futurs projets"])},
  "customer.voucher.set_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définir par défaut"])},
  "customer.voucher.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez un mode de paiement pour poursuivre votre projet"])},
  "customer.voucher.total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "customer.voucher.total_tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total (HT)"])},
  "customer.voucher.vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVA 25 %"])},
  "customer.voucher.voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bon"])},
  "customer.wallet.about.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activez votre portefeuille et définissez le montant de charge et de recharge souhaité."])},
  "customer.wallet.about.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des paiements simplifiés"])},
  "customer.wallet.monthly_spend.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voici le montant de recharge de votre solde créditeur."])},
  "customer.wallet.monthly_spend.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos dépenses mensuelles prévues"])},
  "customer.wallet.re_charge.day.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour de recharge"])},
  "customer.wallet.re_charge.default_method.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez votre méthode par défaut prédéfinie pour recharger le portefeuille."])},
  "customer.wallet.re_charge.default_method.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode de facturation par défaut"])},
  "customer.wallet.re_charge.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez choisir votre type de recharge préféré."])},
  "customer.wallet.re_charge.invoice.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez une facture qui n’est pas payée immédiatement, mais qui vous est envoyée avec SEPA comme seul mode de paiement."])},
  "customer.wallet.re_charge.settings.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fonction de recharge automatique rechargera automatiquement votre portefeuille si votre solde tombe en dessous du solde minimum ou en fonction de la date de recharge automatique préférée."])},
  "customer.wallet.re_charge.settings.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres de recharge automatique"])},
  "customer.wallet.re_charge.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode de facturation de la recharge"])},
  "customer.wallet.status.active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
  "customer.wallet.status.inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactif"])},
  "customer.wallet.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portefeuille"])},
  "customer.workflows.ai_translation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HumanAI"])},
  "customer.workflows.copywriting.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copywriting"])},
  "customer.workflows.copywriting_translation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copywriting &amp; traduction"])},
  "customer.workflows.multilingual.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingue"])},
  "customer.workflows.qa.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôle qualité"])},
  "customer.workflows.translation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduction"])},
  "customer.workflows.translation_review.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduction + révision"])},
  "customre.library.shared.btn.start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrer l'automatisation"])},
  "folders.empty.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun dossier créé"])},
  "folders.empty.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun dossier"])},
  "folders.folder.project_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " projets"])},
  "folders.remove.confirm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer le dossier ", _interpolate(_named("folderName")), " ?"])},
  "folders.remove.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le dossier"])},
  "folders.remove.warning_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre dossier sera définitivement supprimé et cette action est irréversible. Les projets à l'intérieur du dossier ne seront pas supprimés ; ils seront déplacés vers votre vue d'ensemble."])},
  "folders.select.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un dossier"])},
  "label.notification.invite_dms_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez été ajouté à la conversation avec"])},
  "label.notification.invite_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle conversation"])},
  "label.shared.chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discuter"])},
  "label.shared.qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CQ"])},
  "modal.title.supplier.recruitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recrutement de prestataires"])},
  "projects.empty.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez aucun projet en cours."])},
  "projects.empty.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun projet"])},
  "server.validation.accepted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être accepté."])},
  "server.validation.account_workflow_duplicate_string_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flux de travail d'automatisation déjà trouvé pour la bibliothèque sélectionnée"])},
  "server.validation.account_workflow_invalid_initial_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'étape initiale sélectionnée n'est pas autorisée."])},
  "server.validation.account_workflow_invalid_order": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'étape de flux de travail '", _interpolate(_named("step")), "' ne peut pas être suivie de '", _interpolate(_named("following_step")), "'"])},
  "server.validation.active_url": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " n'est pas une URL valide."])},
  "server.validation.add_language_to_library_duplicates": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Langues déjà ajoutées : ", _interpolate(_named("target_languages"))])},
  "server.validation.after": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être une date postérieure à ", _interpolate(_named("date")), "."])},
  "server.validation.after_or_equal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être une date postérieure ou égale à ", _interpolate(_named("date")), "."])},
  "server.validation.alpha": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " ne peut contenir que des lettres."])},
  "server.validation.alpha_dash": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " ne peut contenir que des lettres, des chiffres et des tirets (du 6 et du 8)."])},
  "server.validation.alpha_num": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " ne peut contenir que des lettres et des chiffres."])},
  "server.validation.array": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être un tableau."])},
  "server.validation.before": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être une date antérieure à ", _interpolate(_named("date")), "."])},
  "server.validation.before_or_equal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être une date antérieure ou égale à ", _interpolate(_named("date")), "."])},
  "server.validation.between.array": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit compter entre ", _interpolate(_named("min")), " et ", _interpolate(_named("max")), " éléments."])},
  "server.validation.between.file": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être entre ", _interpolate(_named("min")), " et ", _interpolate(_named("max")), " kilo-octets."])},
  "server.validation.between.numeric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être entre ", _interpolate(_named("min")), " et ", _interpolate(_named("max")), "."])},
  "server.validation.between.string": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit avoir entre ", _interpolate(_named("min")), " et ", _interpolate(_named("max")), " caractères."])},
  "server.validation.boolean": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le champ ", _interpolate(_named("attribute")), " doit être vrai ou faux."])},
  "server.validation.chat_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Impossible d'envoyer des messages pour ", _interpolate(_named("reason"))])},
  "server.validation.company_email_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir une adresse e-mail professionnelle"])},
  "server.validation.confirmed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La confirmation ", _interpolate(_named("attribute")), " ne correspond pas."])},
  "server.validation.country_code": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être un code ISO-3166 alpha-2 valide"])},
  "server.validation.currency_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La valeur fournie n'est pas une devise ISO-4217 valide"])},
  "server.validation.date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " date n'est pas une date valide."])},
  "server.validation.date_equals": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être une date égale à ", _interpolate(_named("date")), "."])},
  "server.validation.date_format": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " ne correspond pas au format ", _interpolate(_named("format")), "."])},
  "server.validation.different": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " et ", _interpolate(_named("other")), " doivent être différents."])},
  "server.validation.digits": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être ", _interpolate(_named("digits")), " chiffres."])},
  "server.validation.digits_between": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être entre ", _interpolate(_named("min")), " et ", _interpolate(_named("max")), " chiffres."])},
  "server.validation.dimensions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " a des dimensions d'image non valides."])},
  "server.validation.distinct": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le champ ", _interpolate(_named("attribute")), " a une valeur en double."])},
  "server.validation.download_library_extra_target_languages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Les langues indiquées ne font pas partie de la bibliothèque : ", _interpolate(_named("language_codes"))])},
  "server.validation.email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être une adresse e-mail valide."])},
  "server.validation.ends_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit se terminer par l'un des éléments suivants : ", _interpolate(_named("values"))])},
  "server.validation.exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " sélectionné est invalide."])},
  "server.validation.file": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être un fichier."])},
  "server.validation.filled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le champ ", _interpolate(_named("attribute")), " doit avoir une valeur."])},
  "server.validation.finish_automation_bulk_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de mettre à jour en masse la langue source via le flux de travail"])},
  "server.validation.finish_automation_for_completed_workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flux de travail terminé"])},
  "server.validation.gdpr_action_requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'action a déjà été demandée"])},
  "server.validation.gdpr_supplier_pending_payouts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de demander la suppression, car vous avez des paiements en attente"])},
  "server.validation.gdpr_supplier_pending_tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de demander la suppression, car vous avez des tâches en attente"])},
  "server.validation.gt.array": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit avoir plus de ", _interpolate(_named("value")), " éléments."])},
  "server.validation.gt.file": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être supérieur à ", _interpolate(_named("value")), " kilo-octets."])},
  "server.validation.gt.numeric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être supérieur à ", _interpolate(_named("value")), "."])},
  "server.validation.gt.string": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être supérieur à ", _interpolate(_named("value")), " caractères."])},
  "server.validation.gte.array": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit avoir ", _interpolate(_named("value")), " articles ou plus."])},
  "server.validation.gte.file": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être supérieur ou égal à ", _interpolate(_named("value")), " kilo-octets."])},
  "server.validation.gte.numeric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être supérieur ou égal à ", _interpolate(_named("value")), "."])},
  "server.validation.gte.string": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être supérieur ou égal à ", _interpolate(_named("value")), " caractères."])},
  "server.validation.image": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être une image."])},
  "server.validation.in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " sélectionné est invalide."])},
  "server.validation.in_array": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le champ ", _interpolate(_named("attribute")), " n'existe pas dans ", _interpolate(_named("other")), "."])},
  "server.validation.integer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être un entier."])},
  "server.validation.ip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être une adresse IP valide."])},
  "server.validation.ipv4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être une adresse IPv4 valide."])},
  "server.validation.ipv6": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être une adresse IPv6 valide."])},
  "server.validation.json": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être une chaîne JSON valide."])},
  "server.validation.language_code": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être un code de langue valide"])},
  "server.validation.login_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ces informations d'identification ne correspondent pas à nos dossiers."])},
  "server.validation.lt.array": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit avoir moins de ", _interpolate(_named("value")), " éléments."])},
  "server.validation.lt.file": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être inférieur à ", _interpolate(_named("value")), " kilo-octets."])},
  "server.validation.lt.numeric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être inférieur à ", _interpolate(_named("value")), "."])},
  "server.validation.lt.string": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être inférieur à ", _interpolate(_named("value")), " caractères."])},
  "server.validation.lte.array": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " ne doit pas avoir plus de ", _interpolate(_named("value")), " éléments."])},
  "server.validation.lte.file": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être inférieur ou égal à ", _interpolate(_named("value")), " kilo-octets."])},
  "server.validation.lte.numeric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être inférieur ou égal à ", _interpolate(_named("value")), "."])},
  "server.validation.lte.string": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être inférieur ou égal à ", _interpolate(_named("value")), " caractères."])},
  "server.validation.match_supplier_delete_service_from_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de supprimer la prestation. Fait déjà partie de l'équipe"])},
  "server.validation.match_supplier_missing_project_template_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléversez un fichier afin que nous puissions traiter vos futurs fichiers. Veuillez noter qu'il doit s'agir d'un petit fichier"])},
  "server.validation.match_supplier_update_price_per_word_on_laas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de modifier le prix au mot pour les clients LaaS"])},
  "server.validation.max.array": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " ne doit pas avoir plus de ", _interpolate(_named("max")), " éléments."])},
  "server.validation.max.file": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " ne doit pas être supérieur à ", _interpolate(_named("max")), " kilo-octets."])},
  "server.validation.max.numeric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " ne doit être supérieur à ", _interpolate(_named("max")), "."])},
  "server.validation.max.string": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " ne doit pas être supérieur à ", _interpolate(_named("max")), " caractères."])},
  "server.validation.mimes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être un fichier de type : ", _interpolate(_named("values")), "."])},
  "server.validation.mimetypes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être un fichier de type : ", _interpolate(_named("values")), "."])},
  "server.validation.min.array": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit avoir au moins ", _interpolate(_named("min")), " éléments."])},
  "server.validation.min.file": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit faire au moins ", _interpolate(_named("min")), " kilo-octets."])},
  "server.validation.min.numeric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être au moins ", _interpolate(_named("min")), "."])},
  "server.validation.min.string": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit avoir au moins ", _interpolate(_named("min")), " caractères."])},
  "server.validation.not_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " sélectionné est invalide."])},
  "server.validation.not_regex": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le format ", _interpolate(_named("attribute")), " n'est pas valide."])},
  "server.validation.numeric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être un nombre."])},
  "server.validation.password_reset_error_throttled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez patienter avant de réessayer."])},
  "server.validation.password_reset_error_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce jeton de réinitialisation de mot de passe n'est pas valide"])},
  "server.validation.password_reset_error_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous ne trouvons pas d'utilisateur avec cette adresse e-mail."])},
  "server.validation.present": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le champ ", _interpolate(_named("attribute")), " doit être présent."])},
  "server.validation.price_list_discounts_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format fourni invalide"])},
  "server.validation.price_list_languages_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format fourni invalide"])},
  "server.validation.project_creation_meta_value_too_long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La méta-valeur de ", _interpolate(_named("key")), " clé est plus longue que ", _interpolate(_named("limit")), " caractères"])},
  "server.validation.project_creation_missing_billing_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous ne pouvons pas traiter votre commande sans vos coordonnées de facturation. Veuillez les ajouter dans la partie facturation."])},
  "server.validation.project_creation_missing_billing_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avant de créer un nouveau projet, au moins une méthode de facturation doit être fournie"])},
  "server.validation.project_creation_missing_project_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il n'y a pas de modèle de projet configuré pour ", _interpolate(_named("source_language")), " ", _interpolate(_named("identifier"))])},
  "server.validation.project_creation_missing_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre abonnement a expiré"])},
  "server.validation.project_creation_missing_translation_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une équipe de traduction est requise pour les combinaisons de langues source/cible demandées"])},
  "server.validation.project_creation_same_source_and_target_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une paire de langues composées de langues source et cible identiques n’est pas autorisée"])},
  "server.validation.project_creation_supplier_without_account_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les paires de langues données du fournisseur ne sont pas appliquées au client"])},
  "server.validation.project_status_not_ready": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Action invalide. Le projet n'est pas ", _interpolate(_named("status"))])},
  "server.validation.regex": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le format ", _interpolate(_named("attribute")), " n'est pas valide."])},
  "server.validation.registration_private_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir une adresse e-mail professionnelle"])},
  "server.validation.registration_sso_not_verified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'e-mail n'est pas encore vérifié"])},
  "server.validation.required": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le champ ", _interpolate(_named("attribute")), " est obligatoire."])},
  "server.validation.required_if": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le champ ", _interpolate(_named("attribute")), " est obligatoire lorsque ", _interpolate(_named("other")), " est ", _interpolate(_named("value")), "."])},
  "server.validation.required_unless": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le champ ", _interpolate(_named("attribute")), " est obligatoire sauf si ", _interpolate(_named("other")), " est dans ", _interpolate(_named("values")), "."])},
  "server.validation.required_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le champ ", _interpolate(_named("attribute")), " est obligatoire lorsque ", _interpolate(_named("values")), " est présent."])},
  "server.validation.required_with_all": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le champ ", _interpolate(_named("attribute")), " est obligatoire lorsque ", _interpolate(_named("values")), " sont présents."])},
  "server.validation.required_without": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le champ ", _interpolate(_named("attribute")), " est obligatoire lorsque ", _interpolate(_named("values")), " n'est pas présent."])},
  "server.validation.required_without_all": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le champ ", _interpolate(_named("attribute")), " est obligatoire lorsqu'aucun des ", _interpolate(_named("values")), " n’est présent."])},
  "server.validation.same": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " et ", _interpolate(_named("other")), " doit correspondre."])},
  "server.validation.size.array": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit contenir ", _interpolate(_named("size")), " éléments."])},
  "server.validation.size.file": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit faire ", _interpolate(_named("size")), " kilo-octets."])},
  "server.validation.size.numeric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être ", _interpolate(_named("size")), "."])},
  "server.validation.size.string": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit faire ", _interpolate(_named("size")), " caractères."])},
  "server.validation.sso_unable_to_use_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les utilisateurs enregistrés avec authentification unique ne sont pas autorisés à se connecter avec un mot de passe ni à réinitialiser leur mot de passe"])},
  "server.validation.start_automation_extra_target_languages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Impossible de démarrer l'automatisation pour les langues cibles qui ne font pas partie de la bibliothèque : ", _interpolate(_named("target_languages"))])},
  "server.validation.start_automation_for_disabled_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de démarrer l'automatisation. La bibliothèque est désactivée"])},
  "server.validation.start_automation_for_disabled_library_automation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de démarrer l'automatisation. L'automatisation de la bibliothèque n'est pas définie ou le flux de travail n'est pas actif !"])},
  "server.validation.start_automation_for_source_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de démarrer l'automatisation pour la langue source"])},
  "server.validation.start_automation_for_wrong_library_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'automatisation ne peut pas être appliquée à la bibliothèque de révision"])},
  "server.validation.starts_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit commencer par l'un des éléments suivants : ", _interpolate(_named("values"))])},
  "server.validation.string": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être une chaîne."])},
  "server.validation.subscription_invalid_subscription_tier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le niveau ", _interpolate(_named("tier")), " n'existe pas pour ", _interpolate(_named("metric"))])},
  "server.validation.subscription_maximum_tier_reached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maximum autorisé ", _interpolate(_named("maximum")), " unités pour ", _interpolate(_named("metric")), " pendant ", _interpolate(_named("plan")), " (", _interpolate(_named("tier")), ")"])},
  "server.validation.subscription_unable_to_update_subscription_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de mettre à jour les niveaux d'abonnement LaaS"])},
  "server.validation.task_revision_unconfirmed_segments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tâche comporte des segments non confirmés. Veuillez vérifier."])},
  "server.validation.team_identifier_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seules des lettres minuscules avec un tiret et des chiffres sont autorisées ; il n’est pas autorisé de commencer ou de terminer par un tiret."])},
  "server.validation.template_not_found": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aucun modèle avec la langue source", _interpolate(_named("source_language")), " et la langue cible", _interpolate(_named("target_languages")), " n’a pu être trouvé. Veuillez contacter l'équipe d'assistance"])},
  "server.validation.throttle_login_request": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Trop de tentatives de connexion. Veuillez réessayer dans ", _interpolate(_named("seconds")), " secondes."])},
  "server.validation.throttle_process": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le processus a été lancé il y a déjà ", _interpolate(_named("minutes")), " minutes, veuillez réessayer plus tard"])},
  "server.validation.timezone": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être une zone valide."])},
  "server.validation.unable_to_modify_predefined_entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de modifier l'entité predefined_entity"])},
  "server.validation.unique": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " a déjà été pris."])},
  "server.validation.unique_domain_email_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le domaine de votre entreprise est déjà enregistré chez nous. Veuillez nous contacter via le chat pour en savoir plus"])},
  "server.validation.update_own": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Impossible de mettre à jour votre ", _interpolate(_named("attribute"))])},
  "server.validation.uploaded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Échec du téléversement de ", _interpolate(_named("attribute")), "."])},
  "server.validation.url": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le format ", _interpolate(_named("attribute")), " n'est pas valide."])},
  "server.validation.user_invitation_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'invitation a déjà été acceptée"])},
  "server.validation.user_invitation_declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'invitation a déjà été refusée"])},
  "server.validation.uuid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être un UUID valide."])},
  "server.validation.vat_invalid_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro de TVA fourni a un format non valide."])},
  "server.validation.wallet_greater_invalid_amount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " doit être au moins ", _interpolate(_named("amount")), " ", _interpolate(_named("currency"))])},
  "server.validation.wallet_invalid_currency_for_recharge_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La méthode de facturation n'est disponible que pour la devise ", _interpolate(_named("currency"))])},
  "server.validation.wallet_invalid_threshold_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le solde minimum ne peut pas être supérieur au solde"])},
  "server.validation.wallet_missing_threshold_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins une option d'authentification de recharge est requise"])},
  "server.validation.wallet_positive_credit_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre solde créditeur est positif. Veuillez contacter l'assistance."])},
  "server.validation.wallet_unable_to_disable_for_freemium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de désactiver le portefeuille pour l'abonnement Freemium"])},
  "server.validation.zip_file_not_ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le fichier ZIP n'est pas encore prêt"])},
  "server.validaton.supplier_onboarding_no_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, nous n'avons pas de traducteur.rice.s pour cette combinaison. Veuillez nous contacter via le chat."])},
  "shard.label.multiple_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plusieurs langues"])},
  "shard.label.one_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une langue"])},
  "shared.account.account_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les informations de votre compte"])},
  "shared.account.profile_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les informations de votre profil"])},
  "shared.account_list.label.choose_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez votre compte"])},
  "shared.account_list.label.choose_supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez votre fournisseur"])},
  "shared.account_list.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le type de client"])},
  "shared.banner.donation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Laissez un avis sur G2 - Nous ferons un don de 25 € à l'Ukraine pour chaque avis non anonyme que nous recevons ", _interpolate(_named("here")), ". Il vous reste ", _interpolate(_named("days")), " jours."])},
  "shared.button.accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])},
  "shared.button.add_education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter diplôme"])},
  "shared.button.add_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter plus"])},
  "shared.button.add_more_keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter plus de mots-clés (facultatif)"])},
  "shared.button.add_tb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une tuberculose"])},
  "shared.button.add_tm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une MT"])},
  "shared.button.approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approuver"])},
  "shared.button.approve_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approuver le paiement"])},
  "shared.button.back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
  "shared.button.back_translator_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la page traducteur"])},
  "shared.button.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "shared.button.cancel_invitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler l'invitation"])},
  "shared.button.change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer"])},
  "shared.button.chat_supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher la progression"])},
  "shared.button.clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer"])},
  "shared.button.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
  "shared.button.complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complet"])},
  "shared.button.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
  "shared.button.confirm_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer&amp;suivant"])},
  "shared.button.connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
  "shared.button.content_in_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir le contenu de ma bibliothèque"])},
  "shared.button.copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copié"])},
  "shared.button.copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texte publicitaire"])},
  "shared.button.create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte"])},
  "shared.button.create_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un document"])},
  "shared.button.create_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau dossier"])},
  "shared.button.create_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un projet"])},
  "shared.button.create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un modèle"])},
  "shared.button.decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser"])},
  "shared.button.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
  "shared.button.delete_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le compte"])},
  "shared.button.delete_flow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le flux"])},
  "shared.button.delete_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le dossier"])},
  "shared.button.delete_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la clé"])},
  "shared.button.delete_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la langue"])},
  "shared.button.delete_language_pair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la paire de langues"])},
  "shared.button.delete_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la bibliothèque"])},
  "shared.button.delete_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la prestation"])},
  "shared.button.delete_this_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer cette action et tout ce qui suit"])},
  "shared.button.delete_time_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer les congés"])},
  "shared.button.disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver"])},
  "shared.button.done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fait"])},
  "shared.button.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
  "shared.button.download_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le fichier"])},
  "shared.button.download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger la traduction"])},
  "shared.button.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
  "shared.button.edit_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la liste"])},
  "shared.button.edit_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le prix"])},
  "shared.button.edit_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les prestations"])},
  "shared.button.edit_this_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier cette action"])},
  "shared.button.embed_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code embed"])},
  "shared.button.enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer"])},
  "shared.button.filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
  "shared.button.finish_onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminer l'onboarding"])},
  "shared.button.finish_revision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminer la révision"])},
  "shared.button.forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié"])},
  "shared.button.get_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer"])},
  "shared.button.go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenir en arrière"])},
  "shared.button.here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ici"])},
  "shared.button.import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import"])},
  "shared.button.invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer une invitation"])},
  "shared.button.learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus"])},
  "shared.button.lets_get_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commençons !"])},
  "shared.button.load_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En charger plus"])},
  "shared.button.login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
  "shared.button.memsource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller à Memsource"])},
  "shared.button.move_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déplacer vers un dossier"])},
  "shared.button.next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prochain"])},
  "shared.button.no_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non, annuler"])},
  "shared.button.phrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher la tâche dans Phrase"])},
  "shared.button.preselect_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langues présélectionnées"])},
  "shared.button.previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])},
  "shared.button.remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer"])},
  "shared.button.remove_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer le compte"])},
  "shared.button.remove_translator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer le traducteur"])},
  "shared.button.remove_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer l'utilisateur"])},
  "shared.button.rename_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renommer le dossier"])},
  "shared.button.request_account_deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander la suppression du compte"])},
  "shared.button.request_company_deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander la suppression de l'entreprise"])},
  "shared.button.resolve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résoudre"])},
  "shared.button.revise_generated_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réviser le contenu généré"])},
  "shared.button.revise_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réviser la traduction"])},
  "shared.button.revision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir l'éditeur de révision"])},
  "shared.button.save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
  "shared.button.save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder le brouillon"])},
  "shared.button.search_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher des actifs"])},
  "shared.button.see_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tout"])},
  "shared.button.see_all_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir toutes les prestations"])},
  "shared.button.see_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Voir en ", _interpolate(_named("format"))])},
  "shared.button.send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
  "shared.button.set_word_credits_ratio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher la répartition des crédits de mots"])},
  "shared.button.set_word_credits_ratio.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher votre coût en crédits de mots par service"])},
  "shared.button.set_word_credits_ratio.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coût du crédit de mots"])},
  "shared.button.settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglages"])},
  "shared.button.skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorer"])},
  "shared.button.skip_for_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorer pour l'instant"])},
  "shared.button.start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer"])},
  "shared.button.start_translating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez à traduire"])},
  "shared.button.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumettre"])},
  "shared.button.switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer de compte"])},
  "shared.button.try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essayer à nouveau"])},
  "shared.button.update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour"])},
  "shared.button.update_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour l'abonnement"])},
  "shared.button.verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier"])},
  "shared.button.verify_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier mon compte"])},
  "shared.button.video_tutorial": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tutoriel vidéo sur la façon d'ajouter ", _interpolate(_named("title"))])},
  "shared.button.view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tout"])},
  "shared.button.yes_im_sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui, j'en suis sûr"])},
  "shared.button.zip_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargement zip"])},
  "shared.caas.ads.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générez du contenu publicitaire et augmentez vos taux de conversion"])},
  "shared.caas.ads.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicités"])},
  "shared.caas.blogs.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générez des articles de blog convaincants et de haute qualité"])},
  "shared.caas.blogs.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billets de blog"])},
  "shared.caas.button.clear_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer l'entrée"])},
  "shared.caas.button.lets_go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacer &amp; écrire"])},
  "shared.caas.button.replace_and_write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacer &amp; écrire"])},
  "shared.caas.button.start_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrer la traduction"])},
  "shared.caas.button.write_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer à écrire"])},
  "shared.caas.buttons.document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document"])},
  "shared.caas.buttons.reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence de contenu"])},
  "shared.caas.data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EasyTranslate vous encourage, en tant que responsable du traitement, à anonymiser toute saisie avant de générer du contenu afin de minimiser le flux de données personnelles (minimisation des données, article 5 du RGPD)."])},
  "shared.caas.emails.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générez du contenu attirant pour les e-mails automatisés"])},
  "shared.caas.emails.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mails"])},
  "shared.caas.modal.check_accurateContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du contenu précis dans n'importe quelle langue"])},
  "shared.caas.modal.check_highQuality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du contenu de haute qualité - rapide et personnalisé"])},
  "shared.caas.modal.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez noter que les mots générés à l'aide de cet outil seront pris en compte dans l’utilisation globale des mots de votre plan de licence."])},
  "shared.caas.modal.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il vous suffit de saisir votre demande (ex. « Écrire un article de blog sur l'IA ») ; un contenu attrayant est généré en quelques secondes seulement."])},
  "shared.caas.modal.text_freemium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il vous suffit de saisir votre demande (ex. « Écrire un article de blog sur l'IA ») ; un contenu attrayant est généré en quelques secondes seulement."])},
  "shared.caas.modal.text_freemium_letsGetStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez à utiliser la plateforme ! Commençons par notre outil de génération de contenu d’IA :"])},
  "shared.caas.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous venons de publier notre nouvelle fonctionnalité de contenu d’IA et vous pouvez l'essayer GRATUITEMENT !"])},
  "shared.caas.modal.title_freemium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue chez EasyTranslate !"])},
  "shared.caas.product.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générez des descriptions de produits attrayantes"])},
  "shared.caas.product.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descriptifs des produits"])},
  "shared.caas.reset_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le modèle aux paramètres par défaut"])},
  "shared.caas.sidebar.advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avancé"])},
  "shared.caas.sidebar.basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basique"])},
  "shared.caas.sidebar.creativity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créativité"])},
  "shared.caas.sidebar.frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fréquence répétitive"])},
  "shared.caas.sidebar.high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élevé"])},
  "shared.caas.sidebar.instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructions"])},
  "shared.caas.sidebar.low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faible"])},
  "shared.caas.sidebar.maxchars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre maximum de caractères"])},
  "shared.caas.sidebar.maxchars.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous recommandons de désactiver le nombre maximum de mots si vous appliquez cette fonctionnalité"])},
  "shared.caas.sidebar.presence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présence répétitive"])},
  "shared.caas.sidebar.tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre maximal de mots"])},
  "shared.caas.sidebar.tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outils"])},
  "shared.caas.sidebar.video.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment générer du contenu attrayant"])},
  "shared.caas.sidebar.watch_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regardez le tutoriel pour la génération de contenu de blog"])},
  "shared.caas.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laissez notre outil d'IA écrire du contenu attirant à votre place"])},
  "shared.caas.subtitle_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez écrire vos invites dans la langue de votre choix (par exemple « Schreibe einen Artikel über KI »)"])},
  "shared.caas.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Génération de contenu"])},
  "shared.caas.tooltip.replace_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette action remplace votre commande par la combinaison (Mac : command + entrée, autre : ctrl + entrée)"])},
  "shared.caas.tooltip.writing_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette action poursuit votre invite initiale"])},
  "shared.chat.actions.leave_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter la conversation"])},
  "shared.chat.actions.leave_conversation_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment quitter cette conversation ?"])},
  "shared.chat.button.go_offline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Définissez-vous comme ", _interpolate(_named("offline"))])},
  "shared.chat.button.go_online": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Définissez-vous comme ", _interpolate(_named("online"))])},
  "shared.chat.button.new_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau message"])},
  "shared.chat.button.show_all_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tous les membres"])},
  "shared.chat.buttons.delete_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le message"])},
  "shared.chat.buttons.save_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer l'élément"])},
  "shared.chat.channels.about_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De quoi parle cette chaîne ?"])},
  "shared.chat.channels.btn.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une chaîne"])},
  "shared.chat.channels.choose_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des membres"])},
  "shared.chat.channels.make_private_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendre privé"])},
  "shared.chat.channels.modal.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les chaînes sont les endroits où votre équipe communique. L’idéal est de les organiser autour d'un thème - #marketing, par exemple."])},
  "shared.chat.channels.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une chaîne"])},
  "shared.chat.channels.private_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsqu'une chaîne est définie comme privée, elle ne peut être consultée ou rejointe que sur invitation."])},
  "shared.chat.channels.share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager en dehors d'EasyTranslate"])},
  "shared.chat.customer_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il s'agit d'une discussion de groupe. Le client et tous les linguistes impliqués dans cette tâche verront votre message. La fonction de discussion n'est pas prise en charge pour les tâches CQ."])},
  "shared.chat.delete_channel.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la chaîne"])},
  "shared.chat.delete_direct_message.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le message direct"])},
  "shared.chat.group_message.btn.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un message de groupe"])},
  "shared.chat.group_message.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un message de groupe"])},
  "shared.chat.label.actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "shared.chat.label.attachments": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez reçu ", _interpolate(_named("count")), " pièce(s) jointe(s) :"])},
  "shared.chat.label.channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaîne"])},
  "shared.chat.label.channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaînes"])},
  "shared.chat.label.dm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message direct"])},
  "shared.chat.label.dms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages directs"])},
  "shared.chat.label.group_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages de groupe"])},
  "shared.chat.label.group_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages de groupe"])},
  "shared.chat.label.more_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus d'actions"])},
  "shared.chat.label.new_message_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À"])},
  "shared.chat.label.new_message_to.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a quelqu’un ?"])},
  "shared.chat.label.remove_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer des membres"])},
  "shared.chat.label.saved_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Articles enregistrés"])},
  "shared.chat.search_channels.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher toutes les chaînes..."])},
  "shared.chat.supplier.client_gets_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre client ", _interpolate(_named("customer")), " recevra vos messages."])},
  "shared.chat.supplier_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il s'agit d'une discussion de groupe. Le client et tous les linguistes impliqués dans cette tâche verront votre message."])},
  "shared.combination.no_match": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il n'y a pas de combinaison ", _interpolate(_named("lang")), ". Veuillez ajouter des traducteurs supplémentaires à votre équipe en cliquant ", _interpolate(_named("here"))])},
  "shared.content_form.automation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisez les tâches répétitives et chronophages"])},
  "shared.content_form.files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichiers standards"])},
  "shared.content_form.files_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ex. : excel, word, xliff, json"])},
  "shared.content_form.improve_quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Améliorez la qualité et la continuité des traductions"])},
  "shared.content_form.new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je suis nouveau, je n'ai pas encore de contenu"])},
  "shared.content_form.plugins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plugins"])},
  "shared.content_form.repository": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un répertoire"])},
  "shared.content_form.repository_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par exemple : Github, Gitlab"])},
  "shared.content_form.start_localisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrer la localisation à partir de zéro"])},
  "shared.content_form.streamline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniformisez les flus de travail internes et la gestion des tâches"])},
  "shared.content_generation.apply_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer le modèle"])},
  "shared.content_generation.content_brief": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résumé du contenu"])},
  "shared.content_generation.delete_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le document"])},
  "shared.content_generation.delete_document.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer le document suivant :"])},
  "shared.content_generation.delete_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le modèle"])},
  "shared.content_generation.delete_template.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer le modèle suivant :"])},
  "shared.content_generation.error_empty_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'invite ne peut pas être vide"])},
  "shared.content_generation.error_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le paramètre de nombre maximal de mots doit être d'au moins 12"])},
  "shared.content_generation.import_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer modèle"])},
  "shared.content_generation.main_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujet principal"])},
  "shared.content_generation.manage_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez gérer vos documents ici"])},
  "shared.content_generation.manage_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez gérer vos modèles ici"])},
  "shared.content_generation.modals.change_document_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifiez le nom de votre document ici"])},
  "shared.content_generation.modals.change_template_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifiez le nom de votre modèle ici"])},
  "shared.content_generation.modals.rename_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le nom du document"])},
  "shared.content_generation.modals.rename_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer le nom du modèle"])},
  "shared.content_generation.modals.save_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer le document"])},
  "shared.content_generation.modals.save_tempate_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrez votre document en tant que modèle et réutilisez-le en l'important"])},
  "shared.content_generation.modals.save_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer le modèle"])},
  "shared.content_generation.new_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau document vide"])},
  "shared.content_generation.new_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau modèle vide"])},
  "shared.content_generation.template_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle actif"])},
  "shared.content_generation.tone_voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Style"])},
  "shared.content_generation.unsaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non enregistré"])},
  "shared.content_pillar_editor.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditeur de tâches du pilier de contenu"])},
  "shared.copywriting.complete_task.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir terminer la tâche suivante :"])},
  "shared.copywriting.complete_task.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tâche de rédaction complète"])},
  "shared.copywriting.finish_copywriting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminer la rédaction"])},
  "shared.copywriting.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rédaction"])},
  "shared.days.Fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendredi"])},
  "shared.days.Mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lundi"])},
  "shared.days.Sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samedi"])},
  "shared.days.Sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimanche"])},
  "shared.days.Thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeudi"])},
  "shared.days.Tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mardi"])},
  "shared.days.Wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercredi"])},
  "shared.editor.ai_filters.all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout"])},
  "shared.editor.ai_filters.filter_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres de filtre"])},
  "shared.editor.ai_filters.is_ai_modified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifié par l'IA"])},
  "shared.editor.ai_filters.is_human_modified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifié par l'humain"])},
  "shared.editor.ai_filters.is_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segments verrouillés"])},
  "shared.editor.ai_filters.is_untranslatable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segments intraduisibles"])},
  "shared.editor.ai_filters.score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondances de mémoire de traduction"])},
  "shared.editor.btn.confirm_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer tous les segments"])},
  "shared.editor.btn.unconfirm_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler la confirmation de tous les segments"])},
  "shared.editor.linked_segments.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher tous les segments identiques"])},
  "shared.editor.tagging_issues.pro_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conseil de pro : Appliquer le segment source comme source de vérité"])},
  "shared.editor.tagging_issues.step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquer sur « Corriger les balises » ci-dessous"])},
  "shared.editor.tagging_issues.step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier si les balises sont placées correctement"])},
  "shared.editor.tagging_issues.step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si ce n’est pas le cas, cliquer sur la balise et la déposer là où vous le souhaitez"])},
  "shared.editor.tagging_issues.step4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous pouvez également créer des balises en saisissant ", _interpolate(_named("tagExample")), " (assurez-vous d'inscrire le bon numéro)"])},
  "shared.editor.tagging_issues.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce fichier contient des problèmes avec les balises !"])},
  "shared.editor.toast.char_limit_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le segment dépasse la limite de caractères. Veuillez adapter le texte en fonction de la limite de caractères."])},
  "shared.editor_preview.tab.preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])},
  "shared.editor_preview.tab.xlf_notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes XLF"])},
  "shared.embed_code.toast.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code copié"])},
  "shared.empty.clients.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous n'avons trouvé aucun client. Réessayez plus tard."])},
  "shared.empty.clients.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun client"])},
  "shared.empty.history.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun historique à afficher."])},
  "shared.error_titles.generic_not_found": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erreur ", _interpolate(_named("code"))])},
  "shared.errors.generic_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La ressource demandée est introuvable"])},
  "shared.gdpr.delete.admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet utilisateur a demandé la suppression de son compte. Cliquez sur supprimer ci-dessous pour supprimer définitivement son compte."])},
  "shared.gdpr.delete.modal_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je confirme avoir lu et compris le texte ci-dessus, et je souhaite procéder à la suppression de mon compte EasyTranslate."])},
  "shared.gdpr.delete.modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette action supprimera votre compte EasyTranslate, ainsi que les dossiers sélectionnés. Vos données seront supprimées et votre compte EasyTranslate ne sera plus accessible."])},
  "shared.gdpr.delete.modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le compte"])},
  "shared.gdpr.delete.option_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discuter"])},
  "shared.gdpr.delete.option_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["photo"])},
  "shared.gdpr.delete.option_source_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichiers source"])},
  "shared.gdpr.delete.option_target_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichiers cible"])},
  "shared.gdpr.delete.option_tm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mémoire de traduction"])},
  "shared.gdpr.delete.select_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les données que vous souhaitez que nous supprimions de nos dossiers avec votre compte :"])},
  "shared.gdpr.delete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer votre compte"])},
  "shared.gdpr.delete.user_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il existe actuellement un demande ", _interpolate(_named("pending")), " de suppression de ce compte. Nous traitons actuellement votre demande."])},
  "shared.gdpr.delete_company.modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette action supprimera d'EasyTranslate votre entreprise ainsi que tous ses sous-comptes et leur contenu. Vos données seront également définitivement supprimées et votre compte EasyTranslate ne sera plus accessible."])},
  "shared.gdpr.delete_company.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les données que vous souhaitez que nous supprimions de nos dossiers avec votre entreprise :"])},
  "shared.gdpr.delete_company.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer votre entreprise"])},
  "shared.gdpr.delete_company.warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette action supprimera tout ce qui concerne votre entreprise, y compris les sous-comptes et leur contenu."])},
  "shared.gdpr.export.option_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discuter"])},
  "shared.gdpr.export.option_invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["factures"])},
  "shared.gdpr.export.option_personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["personnel"])},
  "shared.gdpr.export.option_source_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichiers langues source"])},
  "shared.gdpr.export.option_target_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichiers langues cible"])},
  "shared.gdpr.export.option_tm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mémoire de traduction"])},
  "shared.gdpr.export.preparing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préparation du fichier zip..."])},
  "shared.gdpr.export.request_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander un nouveau téléchargement"])},
  "shared.gdpr.export.tm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mémoire de traduction"])},
  "shared.gdpr.team_admin.data_retention.never_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jamais"])},
  "shared.gdpr.team_admin.data_retention.standard_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 mois"])},
  "shared.gdpr.team_admin.data_retention_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préférences de stockage"])},
  "shared.gdpr.team_admin.delete.impersonate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En tant qu'administrateur, vous pouvez supprimer les comptes et les données des membres de l'équipe. Mais d'abord, vous devez aller dans Paramètres > Utilisateurs et autorisations > bouton d'options > 'Se connecter à la place de l’utilisateur'"])},
  "shared.gdpr.team_admin.export.impersonate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En tant qu'administrateur, vous pouvez télécharger les données de n'importe quel membre de l'équipe. Mais d'abord, vous devez aller dans Paramètres > Utilisateurs et autorisations > bouton d'options > 'Se connecter à la place de l’utilisateur'"])},
  "shared.gdpr.team_admin.retention_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez si vous souhaitez que nous supprimions vos données au bout de 12 mois (standard) ou si nous n'avons pas besoin de les supprimer (jamais)"])},
  "shared.gdpr.zip.button.download_latest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le dernier fichier (.zip)"])},
  "shared.gdpr.zip.request_in_mins": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous pouvez demander un nouveau dossier dans ", _interpolate(_named("zipTimestamp")), " min"])},
  "shared.gdpr.zip.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargez vos données"])},
  "shared.gdpr.zip.when_ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un e-mail vous sera envoyé lorsque le nouveau fichier zip sera prêt à être téléchargé"])},
  "shared.google_signup.empty_consents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez accepter nos Conditions générales ci-dessous pour pouvoir vous inscrire"])},
  "shared.google_signup.sso_or_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou créer votre compte manuellement"])},
  "shared.header.create_freemium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez votre compte gratuit"])},
  "shared.labe.last_modified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière modification"])},
  "shared.label.account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
  "shared.label.account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de compte"])},
  "shared.label.action_is_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette modification est irréversible"])},
  "shared.label.action_may_take_few_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette action peut prendre quelques minutes"])},
  "shared.label.actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "shared.label.add_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter le fichier"])},
  "shared.label.add_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une nouvelle clé"])},
  "shared.label.address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
  "shared.label.admin_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte administrateur"])},
  "shared.label.ai_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HumanAI"])},
  "shared.label.ai_translation.split_file_dropdown_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diviser un fichier 1.a"])},
  "shared.label.ai_translation.split_file_dropdown_1.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le seuil"])},
  "shared.label.ai_translation.split_file_dropdown_1.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette valeur définit si le fichier peut être divisé en fichiers plus petits. Si le nombre de mots du fichier est inférieur à cette valeur, le fichier ne sera pas divisé. S’il est supérieur, le fichier sera divisé en plusieurs fichiers en fonction du nombre de mots sélectionné."])},
  "shared.label.ai_translation.split_file_dropdown_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diviser un fichier 1.b"])},
  "shared.label.ai_translation.split_file_dropdown_2.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le nombre de mots"])},
  "shared.label.ai_translation.split_file_dropdown_2.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si le nombre de mots du fichier ajouté dépasse le seuil, le fichier sera divisé en projets plus petits, chacun contenant moins de mots que la limite spécifiée ici."])},
  "shared.label.ai_translation.template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle"])},
  "shared.label.ai_translation.template.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le modèle"])},
  "shared.label.all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout"])},
  "shared.label.amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
  "shared.label.any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N'importe lequel"])},
  "shared.label.approval_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approbation nécessaire"])},
  "shared.label.approve_new_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approuver le nouveau prix"])},
  "shared.label.archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
  "shared.label.areas_expertise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domaines d'expertise (max : 3)"])},
  "shared.label.balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde"])},
  "shared.label.bank_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte bancaire"])},
  "shared.label.beta_editor.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette version affiche uniquement les segments signalés. Décocher la version bêta pour afficher tous les segments."])},
  "shared.label.billed_annually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation annuelle"])},
  "shared.label.billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de facturation"])},
  "shared.label.birth_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
  "shared.label.bleu_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note BLEU"])},
  "shared.label.book_meeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prendre rendez-vous"])},
  "shared.label.btn.add_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des balises"])},
  "shared.label.btn.complete_revision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminer la révision"])},
  "shared.label.btn.fix_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corriger les balises"])},
  "shared.label.btn.reach_upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez l'assistance pour mettre à niveau"])},
  "shared.label.business_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'entreprise"])},
  "shared.label.button.message_freelancers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message aux freelances"])},
  "shared.label.card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte"])},
  "shared.label.card_holder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du titulaire"])},
  "shared.label.card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de carte"])},
  "shared.label.cardholder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du titulaire"])},
  "shared.label.change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer le mot de passe"])},
  "shared.label.change_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changez votre photo de profil"])},
  "shared.label.characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caractères"])},
  "shared.label.chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
  "shared.label.chat.today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aujourd'hui"])},
  "shared.label.choose_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir la date"])},
  "shared.label.choose_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir une bibliothèque"])},
  "shared.label.choose_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir le ou les réviseurs"])},
  "shared.label.choose_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir l'heure"])},
  "shared.label.choose_translator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir le traducteur"])},
  "shared.label.choose_translators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir le ou les traducteurs"])},
  "shared.label.city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
  "shared.label.click_here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez ici"])},
  "shared.label.click_to_deactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour désactiver"])},
  "shared.label.client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
  "shared.label.client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du client"])},
  "shared.label.client_not_freemium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le client n'est pas sur freemium"])},
  "shared.label.coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bientôt disponible"])},
  "shared.label.comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires"])},
  "shared.label.company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'entreprise"])},
  "shared.label.company_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone de l'entreprise"])},
  "shared.label.completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminé"])},
  "shared.label.confirm_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le nouveau mot de passe"])},
  "shared.label.confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer votre mot de passe"])},
  "shared.label.confirmed_segments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segments confirmés"])},
  "shared.label.content_pillars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piliers de contenu"])},
  "shared.label.content_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de contenu"])},
  "shared.label.copyeditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rédacteur.rice"])},
  "shared.label.copywriting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rédaction"])},
  "shared.label.copywriting_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarif rédaction"])},
  "shared.label.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
  "shared.label.create_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une nouvelle prestation"])},
  "shared.label.created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé"])},
  "shared.label.created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé par"])},
  "shared.label.created_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Créé le ", _interpolate(_named("date"))])},
  "shared.label.credit_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre solde créditeur"])},
  "shared.label.currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise"])},
  "shared.label.current_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde actuel"])},
  "shared.label.custom_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnaliser le rôle"])},
  "shared.label.customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
  "shared.label.data_retention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer mes fichiers après"])},
  "shared.label.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "shared.label.date_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])},
  "shared.label.deadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date limite"])},
  "shared.label.decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser"])},
  "shared.label.decline_new_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser le nouveau prix"])},
  "shared.label.declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refusé"])},
  "shared.label.default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par défaut"])},
  "shared.label.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "shared.label.description_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description (max. 500 caractères)"])},
  "shared.label.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
  "shared.label.education_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intitulé du diplôme"])},
  "shared.label.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "shared.label.email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
  "shared.label.enter_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez votre mot de passe"])},
  "shared.label.expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expire"])},
  "shared.label.export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])},
  "shared.label.failed_segments.count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segments signalés"])},
  "shared.label.file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier"])},
  "shared.label.filter_segments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer les segments par"])},
  "shared.label.final_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score final"])},
  "shared.label.first_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Première fonctionnalité"])},
  "shared.label.first_flow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premier flux"])},
  "shared.label.first_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Première bibliothèque"])},
  "shared.label.first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
  "shared.label.flow_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description du flux"])},
  "shared.label.flow_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du flux"])},
  "shared.label.forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié"])},
  "shared.label.freelancer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelance"])},
  "shared.label.from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De"])},
  "shared.label.from_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De-à"])},
  "shared.label.from_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À partir de (année)"])},
  "shared.label.gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genre"])},
  "shared.label.generated_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenu généré"])},
  "shared.label.getting_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se lancer"])},
  "shared.label.headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En-tête"])},
  "shared.label.history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique"])},
  "shared.label.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
  "shared.label.human_ai_translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traductions HumanAI"])},
  "shared.label.id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "shared.label.import_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer des actifs"])},
  "shared.label.invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer un e-mail valide"])},
  "shared.label.invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facture"])},
  "shared.label.key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé"])},
  "shared.label.key_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom clé"])},
  "shared.label.keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clés"])},
  "shared.label.keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots clés"])},
  "shared.label.language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
  "shared.label.language_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options de langue"])},
  "shared.label.language_pair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paire de langues"])},
  "shared.label.languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue(s)"])},
  "shared.label.last_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière modification"])},
  "shared.label.last_modified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière modification"])},
  "shared.label.last_modified_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière modification à"])},
  "shared.label.last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
  "shared.label.manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer"])},
  "shared.label.match_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux de correspondance"])},
  "shared.label.max_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisation max."])},
  "shared.label.minimum_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde minimum"])},
  "shared.label.money_saved_tm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argent économisé grâce à la MT"])},
  "shared.label.multilingual_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier multilingue"])},
  "shared.label.my_email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mon adresse email ", _interpolate(_named("email"))])},
  "shared.label.my_tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes tâches"])},
  "shared.label.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "shared.label.native_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue maternelle"])},
  "shared.label.native_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôle natif"])},
  "shared.label.never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jamais"])},
  "shared.label.new_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle action"])},
  "shared.label.new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
  "shared.label.no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
  "shared.label.not_found_freelancers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore ajouté d’indépendant.e.s pour ce service"])},
  "shared.label.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
  "shared.label.number_projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de projets"])},
  "shared.label.offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hors ligne"])},
  "shared.label.online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En ligne"])},
  "shared.label.open_in_cp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher dans les piliers de contenu"])},
  "shared.label.optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["facultatif"])},
  "shared.label.or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ou"])},
  "shared.label.page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page"])},
  "shared.label.pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pages"])},
  "shared.label.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "shared.label.passwords_dont_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe ne correspond pas"])},
  "shared.label.payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement"])},
  "shared.label.payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode de paiement"])},
  "shared.label.pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
  "shared.label.period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période"])},
  "shared.label.permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisations"])},
  "shared.label.phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
  "shared.label.postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
  "shared.label.preferred_app_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue préférée de l'application"])},
  "shared.label.preferred_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date préférée"])},
  "shared.label.preferred_deadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échéance préférée"])},
  "shared.label.preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])},
  "shared.label.previous_revised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent (révisé)"])},
  "shared.label.price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
  "shared.label.price_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix à partir de"])},
  "shared.label.price_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix/mois"])},
  "shared.label.price_per_amount_words": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prix aux ", _interpolate(_named("amount")), " mots"])},
  "shared.label.price_per_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix par tâche"])},
  "shared.label.price_per_word": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix au mot"])},
  "shared.label.price_without_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix (hors TVA)"])},
  "shared.label.pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarification"])},
  "shared.label.processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours de traitement"])},
  "shared.label.product_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit clé"])},
  "shared.label.product_keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots clés du produit"])},
  "shared.label.product_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothèque de produits"])},
  "shared.label.progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progression"])},
  "shared.label.project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projet"])},
  "shared.label.project_identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant du projet"])},
  "shared.label.qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CQ"])},
  "shared.label.qa_parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres du contrôle qualité"])},
  "shared.label.qa_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix du contrôle qualité"])},
  "shared.label.qa_translator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traducteur du contrôle qualité"])},
  "shared.label.quality_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des résultats de qualité"])},
  "shared.label.rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
  "shared.label.receipt_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail de réception"])},
  "shared.label.rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renommer"])},
  "shared.label.replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacer"])},
  "shared.label.replace_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacer tout"])},
  "shared.label.replace_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacer par"])},
  "shared.label.required_indication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* indique un champ obligatoire"])},
  "shared.label.review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révision"])},
  "shared.label.review_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revoir le prix"])},
  "shared.label.reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réviseur"])},
  "shared.label.reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réviseur(s)"])},
  "shared.label.reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations"])},
  "shared.label.revised_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révisé le"])},
  "shared.label.role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôle"])},
  "shared.label.roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôles"])},
  "shared.label.sandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environnement sandbox"])},
  "shared.label.seacrh_and_replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher et remplacer"])},
  "shared.label.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
  "shared.label.see_clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les clients"])},
  "shared.label.segments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segments"])},
  "shared.label.select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout sélectionner"])},
  "shared.label.select_from_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner dans la liste"])},
  "shared.label.select_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir la langue"])},
  "shared.label.select_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les langues"])},
  "shared.label.select_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un niveau"])},
  "shared.label.select_workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un flux de travail"])},
  "shared.label.service_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de prestation"])},
  "shared.label.services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prestations"])},
  "shared.label.set_as_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définir par défaut"])},
  "shared.label.source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
  "shared.label.source_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier source"])},
  "shared.label.source_lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue source"])},
  "shared.label.source_lang_copyediting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
  "shared.label.source_language_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur langue source"])},
  "shared.label.source_s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source(s)"])},
  "shared.label.source_term_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source : Glossaire"])},
  "shared.label.specific_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consignes spécifiques"])},
  "shared.label.specific_instructions.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les commentaires"])},
  "shared.label.specific_instructions.customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires pour votre freelance"])},
  "shared.label.specific_instructions_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci d’utiliser le ton de voix de notre marque"])},
  "shared.label.standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard (12 mois)"])},
  "shared.label.start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer"])},
  "shared.label.start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début d'abonnement"])},
  "shared.label.start_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrer le projet"])},
  "shared.label.started_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencé"])},
  "shared.label.status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
  "shared.label.subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objet"])},
  "shared.label.subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])},
  "shared.label.supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur"])},
  "shared.label.supplier_margin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marge fournisseur"])},
  "shared.label.supported_formats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formats de fichiers pris en charge"])},
  "shared.label.supported_language_pairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paires de langues prises en charge"])},
  "shared.label.table_data_pagination": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Affichage", _interpolate(_named("from")), " -", _interpolate(_named("to")), " sur ", _interpolate(_named("of"))])},
  "shared.label.tag_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incohérence des balises"])},
  "shared.label.tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balises"])},
  "shared.label.target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cible"])},
  "shared.label.target_initial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cible (initiale)"])},
  "shared.label.target_lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue cible"])},
  "shared.label.target_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue(s) cible(s)"])},
  "shared.label.target_revised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cible (révisée)"])},
  "shared.label.target_s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cible(s)"])},
  "shared.label.task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tâche"])},
  "shared.label.task_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de tâche"])},
  "shared.label.team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipe"])},
  "shared.label.team_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateur d'équipe"])},
  "shared.label.terms_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions générales"])},
  "shared.label.tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
  "shared.label.timezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuseau horaire"])},
  "shared.label.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre"])},
  "shared.label.tm_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondance dans la mémoire de traduction"])},
  "shared.label.to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À"])},
  "shared.label.total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "shared.label.total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix total"])},
  "shared.label.total_strings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de chaînes"])},
  "shared.label.total_tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de tâches"])},
  "shared.label.total_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de mots"])},
  "shared.label.total_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de mots"])},
  "shared.label.translate_yourself": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduisez vous-même"])},
  "shared.label.translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduit"])},
  "shared.label.translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduction"])},
  "shared.label.translation_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothèque de traduction"])},
  "shared.label.translation_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduction &amp; révision"])},
  "shared.label.translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traductions"])},
  "shared.label.translator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traducteur"])},
  "shared.label.translators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traducteur(s)"])},
  "shared.label.trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclencher"])},
  "shared.label.type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "shared.label.type_keywords_here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapez vos mots-clés ici"])},
  "shared.label.unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illimité"])},
  "shared.label.unselect_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout déselectionner"])},
  "shared.label.upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
  "shared.label.upload_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléverser le fichier"])},
  "shared.label.upload_files.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou recherchez des fichiers"])},
  "shared.label.upload_files.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faites glisser les fichiers ici pour les télécharger"])},
  "shared.label.used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisé"])},
  "shared.label.user.status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définir le statut de l'utilisateur.rice"])},
  "shared.label.vat_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de TVA"])},
  "shared.label.vat_tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVA (25 %)"])},
  "shared.label.view_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller aux détails du projet"])},
  "shared.label.welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue"])},
  "shared.label.word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de mots"])},
  "shared.label.word_count_pr_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de mots par paire de langues"])},
  "shared.label.words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots"])},
  "shared.label.worflow.name_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce nom de workflow existe déjà"])},
  "shared.label.workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flux de travail"])},
  "shared.label.year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["an"])},
  "shared.label.yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
  "shared.label.yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier"])},
  "shared.label.your_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos messages"])},
  "shared.labels.characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caractères"])},
  "shared.labels.created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé à"])},
  "shared.labels.here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ici."])},
  "shared.labels.or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou"])},
  "shared.labels.workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espace de travail"])},
  "shared.languages.aa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afar"])},
  "shared.languages.af": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afrikaans"])},
  "shared.languages.am": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amharique"])},
  "shared.languages.ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabe"])},
  "shared.languages.ar_EG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabe (Égypte)"])},
  "shared.languages.ar_MA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabe (Maroc)"])},
  "shared.languages.ar_SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabe (Soudan)"])},
  "shared.languages.ar_SY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabe (Syrie)"])},
  "shared.languages.az": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azéri"])},
  "shared.languages.be": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biélorusse"])},
  "shared.languages.bg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgare"])},
  "shared.languages.bm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bambara"])},
  "shared.languages.bn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bengali"])},
  "shared.languages.bo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tibétain"])},
  "shared.languages.bs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosnien"])},
  "shared.languages.byn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilen (blin)"])},
  "shared.languages.ca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalan"])},
  "shared.languages.ce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tchétchène"])},
  "shared.languages.ckb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorani"])},
  "shared.languages.cs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tchèque"])},
  "shared.languages.cy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallois"])},
  "shared.languages.da": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danois"])},
  "shared.languages.de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allemand"])},
  "shared.languages.de_AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allemand (Autriche)"])},
  "shared.languages.de_CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allemand (Suisse)"])},
  "shared.languages.dv_MV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divehi"])},
  "shared.languages.dz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dzongkha"])},
  "shared.languages.el": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grec"])},
  "shared.languages.en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais"])},
  "shared.languages.en_AU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais (Australie)"])},
  "shared.languages.en_GB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais (Royaume-Uni)"])},
  "shared.languages.en_IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English (India)"])},
  "shared.languages.en_US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais (États-Unis)"])},
  "shared.languages.en_ZA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais (Afrique du Sud)"])},
  "shared.languages.eo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espéranto"])},
  "shared.languages.es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espagnol"])},
  "shared.languages.es_419": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espagnol (Amérique latine)"])},
  "shared.languages.es_ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espagnol (Europe)"])},
  "shared.languages.es_MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espagnol (Mexique)"])},
  "shared.languages.et": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estonien"])},
  "shared.languages.eu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basque"])},
  "shared.languages.fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farsi"])},
  "shared.languages.fa_IR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persan (farsi)"])},
  "shared.languages.ff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peul"])},
  "shared.languages.fi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finlandais"])},
  "shared.languages.fil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Philippin"])},
  "shared.languages.fo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Féroïen"])},
  "shared.languages.fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
  "shared.languages.fr_BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français (Belgique)"])},
  "shared.languages.fr_CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français (Canada)"])},
  "shared.languages.fr_CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français (Suisse)"])},
  "shared.languages.fr_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français (standard)"])},
  "shared.languages.fy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frison"])},
  "shared.languages.ga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irlandais"])},
  "shared.languages.gl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galicien"])},
  "shared.languages.gu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gujarati"])},
  "shared.languages.ha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haoussa"])},
  "shared.languages.he": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébreu"])},
  "shared.languages.hi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hindi"])},
  "shared.languages.hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croate"])},
  "shared.languages.hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hongrois"])},
  "shared.languages.hy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arménien"])},
  "shared.languages.id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonésien"])},
  "shared.languages.ig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igbo"])},
  "shared.languages.is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Islandais"])},
  "shared.languages.it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italien"])},
  "shared.languages.it_CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italien (Suisse)"])},
  "shared.languages.it_IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italien (standard)"])},
  "shared.languages.iu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inuktitut"])},
  "shared.languages.ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japonais"])},
  "shared.languages.jv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Javanais"])},
  "shared.languages.ka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Géorgien"])},
  "shared.languages.kk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazakh"])},
  "shared.languages.kl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groenlandais"])},
  "shared.languages.km": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khmer"])},
  "shared.languages.kmr_SY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurmandji (Syrie)"])},
  "shared.languages.kmr_TR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurmandji (Turquie)"])},
  "shared.languages.kn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kannada"])},
  "shared.languages.ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coréen"])},
  "shared.languages.kri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krio"])},
  "shared.languages.kz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirghiz"])},
  "shared.languages.la": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latin"])},
  "shared.languages.lb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxembourgeois"])},
  "shared.languages.ln": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingala"])},
  "shared.languages.lo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lao"])},
  "shared.languages.lt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lituanien"])},
  "shared.languages.lv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letton"])},
  "shared.languages.man": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandingue (mandinka)"])},
  "shared.languages.mg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malgache"])},
  "shared.languages.mk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macédonien"])},
  "shared.languages.ml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malayalam"])},
  "shared.languages.mn_MN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mongol"])},
  "shared.languages.mr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marathi"])},
  "shared.languages.ms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malais"])},
  "shared.languages.mt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maltais"])},
  "shared.languages.my": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birman"])},
  "shared.languages.nb_NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norvégien (Bokmål)"])},
  "shared.languages.ne_NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Népalais"])},
  "shared.languages.nl_BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Néerlandais (Belgique)"])},
  "shared.languages.nl_NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Néerlandais (standard)"])},
  "shared.languages.nn_NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norvégien (Nynorsk)"])},
  "shared.languages.om": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oromo"])},
  "shared.languages.pa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punjabi"])},
  "shared.languages.pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polonais"])},
  "shared.languages.prs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dari"])},
  "shared.languages.ps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pachto"])},
  "shared.languages.pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugais"])},
  "shared.languages.pt_BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugais (Brésil)"])},
  "shared.languages.pt_PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugais (Europe)"])},
  "shared.languages.rm_CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romanche (Suisse)"])},
  "shared.languages.rn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirundi"])},
  "shared.languages.ro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roumain"])},
  "shared.languages.ro_MD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moldave"])},
  "shared.languages.ro_RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roumain"])},
  "shared.languages.rom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romani"])},
  "shared.languages.ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russe"])},
  "shared.languages.rw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinyarwanda"])},
  "shared.languages.se_FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sami"])},
  "shared.languages.si_LK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cinghalais"])},
  "shared.languages.sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovaque"])},
  "shared.languages.sl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovène"])},
  "shared.languages.so": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somali"])},
  "shared.languages.sq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albanais"])},
  "shared.languages.sr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbe"])},
  "shared.languages.sr_ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbe (Monténégro)"])},
  "shared.languages.sr_cyrl_ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbe (cyrillique, Monténégro)"])},
  "shared.languages.sr_cyrl_RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbe (cyrillique, Serbie)"])},
  "shared.languages.sr_latn_ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbe (latin, Monténégro)"])},
  "shared.languages.sr_latn_RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbe (latin, Serbie)"])},
  "shared.languages.sr_latn_SR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbe (latin, Serbie)"])},
  "shared.languages.st": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sotho"])},
  "shared.languages.sv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suédois"])},
  "shared.languages.sw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swahili"])},
  "shared.languages.ta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamoul"])},
  "shared.languages.te": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télougou"])},
  "shared.languages.tg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tadjik"])},
  "shared.languages.th": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thaï"])},
  "shared.languages.ti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tigrinya"])},
  "shared.languages.tig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tigre"])},
  "shared.languages.tk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkmène"])},
  "shared.languages.tl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagalog"])},
  "shared.languages.tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turc"])},
  "shared.languages.tw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twi"])},
  "shared.languages.ug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouïghour"])},
  "shared.languages.uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukrainien"])},
  "shared.languages.ur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ourdou"])},
  "shared.languages.uz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouzbek"])},
  "shared.languages.vi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnamien"])},
  "shared.languages.wo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wolof"])},
  "shared.languages.xh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xhosa"])},
  "shared.languages.yi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yiddish"])},
  "shared.languages.yo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yoruba"])},
  "shared.languages.zh_CN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinois (simplifié)"])},
  "shared.languages.zh_HK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinois traditionnel (Hong Kong)"])},
  "shared.languages.zh_TW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinois traditionnel (Taïwan)"])},
  "shared.languages.zu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoulou"])},
  "shared.loader.text.0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre pouce gauche pointe vers la droite et votre pouce droit pointe vers la gauche."])},
  "shared.loader.text.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne vous inquiétez pas. Tout est sous contrôle"])},
  "shared.loader.text.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["...au moins on ne vous met pas en attente..."])},
  "shared.loader.text.3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et si on parlait du temps ?"])},
  "shared.loader.text.4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a des licornes au bout du tunnel, promis"])},
  "shared.loader.text.5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On est à fond"])},
  "shared.metric.caas_generated_words.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour la génération de contenu"])},
  "shared.metric.custom_machine_translation.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour une traduction automatique personnalisée"])},
  "shared.metric.string_library_keys.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dans vos bibliothèques de chaînes"])},
  "shared.metric.supplier_team.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dans votre équipe de fournisseurs"])},
  "shared.metric.supplier_translated_words.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour la traduction du fournisseur"])},
  "shared.mfa.authenticator.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier avec le code d'authentification à deux facteurs"])},
  "shared.mfa.banner_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez une couche de sécurité supplémentaire à votre compte en activant l'authentification à deux facteurs."])},
  "shared.mfa.banner_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification à deux facteurs"])},
  "shared.mfa.confirm_password_disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour désactiver la 2FA, vous devez confirmer votre mot de passe."])},
  "shared.mfa.confirm_password_enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour activer la 2FA, vous devez confirmer votre mot de passe."])},
  "shared.mfa.enable_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsque vous activez l'authentification à deux facteurs, vous ajoutez une couche de sécurité supplémentaire à votre compte. Lors de la connexion, il vous sera demandé d'entrer un code aléatoire généré par l'application d'authentification de votre choix."])},
  "shared.mfa.enable_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer l'authentification à deux facteurs"])},
  "shared.mfa.enabled_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà activé l'authentification à deux facteurs. Vous pouvez la désactiver en cliquant sur « Désactiver » ci-dessous."])},
  "shared.mfa.recovery_code.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier avec un code de récupération"])},
  "shared.mfa.scan_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurez-vous de scanner ce code QR avec l'application d'authentification de votre choix et de copier vos codes de récupération dans un endroit sûr. Le code QR et les codes ne seront affichés qu'une seule fois et seront détruits lorsque vous fermerez cette fenêtre de dialogue."])},
  "shared.mfa.scan_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scannez votre code QR"])},
  "shared.mfa.verify_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez votre authentification à deux facteurs"])},
  "shared.modal.repetitions.linked_segments.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les segments auxquels vous souhaitez appliquer vos modifications"])},
  "shared.modal.repetitions.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaitez-vous appliquer vos modifications sur tous les segments identiques ? Cliquer sur oui activera cette fonction pour tous les segments identiques"])},
  "shared.modal.repetitions.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce segment apparaît plusieurs fois."])},
  "shared.nav.account.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
  "shared.nav.logout.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
  "shared.nav.news.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualités"])},
  "shared.nav.news.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quoi de neuf ?"])},
  "shared.nav.payment.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement"])},
  "shared.nav.settings.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglages"])},
  "shared.news.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous évoluons constamment ! Restez informé de nos dernières mises à jour, fonctionnalités et plus encore."])},
  "shared.news.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mises à jour récentes"])},
  "shared.pagination.items_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Articles par page"])},
  "shared.pagination.page_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur la page n°"])},
  "shared.placceholder.no_result_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat trouvé"])},
  "shared.placeholder.caas_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["café, foncé, expresso, boutique, 15 g, caféine"])},
  "shared.placeholder.channel_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description (facultatif)"])},
  "shared.placeholder.channel_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "shared.placeholder.city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
  "shared.placeholder.comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscrivez votre commentaire ici..."])},
  "shared.placeholder.comment_freelancer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laissez un commentaire à votre freelance"])},
  "shared.placeholder.confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le mot de passe"])},
  "shared.placeholder.csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insérer un fichier csv"])},
  "shared.placeholder.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscrivez la description ici..."])},
  "shared.placeholder.description_here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez une description ici"])},
  "shared.placeholder.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
  "shared.placeholder.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vide"])},
  "shared.placeholder.filter_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filtrer le texte source"])},
  "shared.placeholder.filter_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filtrer le texte cible"])},
  "shared.placeholder.folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de dossier"])},
  "shared.placeholder.format.csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".csv"])},
  "shared.placeholder.key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé"])},
  "shared.placeholder.library_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la bibliothèque"])},
  "shared.placeholder.new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau nom"])},
  "shared.placeholder.no_match_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune correspondance trouvée"])},
  "shared.placeholder.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "shared.placeholder.project_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du dossier (facultatif pour 1 fichier, obligatoire pour plusieurs)"])},
  "shared.placeholder.project_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID du projet"])},
  "shared.placeholder.project_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du projet"])},
  "shared.placeholder.project_name_optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du projet (facultatif)"])},
  "shared.placeholder.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher..."])},
  "shared.placeholder.search_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les résultats de votre recherche apparaîtront ici"])},
  "shared.placeholder.search_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un nom ou un identifiant"])},
  "shared.placeholder.translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduction"])},
  "shared.placeholder.type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapez votre message ici..."])},
  "shared.placeholder.type_in_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapez votre réponse ici"])},
  "shared.placeholder.type_message_here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapez votre message ici"])},
  "shared.placeholder.upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déposez votre fichier ici ou cliquez pour téléverser"])},
  "shared.qa.accuracy.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signification erronée, erreurs de chiffres et de références, omissions ou balises incorrectes."])},
  "shared.qa.accuracy.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précision"])},
  "shared.qa.comment.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
  "shared.qa.error_severities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gravités des erreurs"])},
  "shared.qa.language.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreurs de ponctuation, d'orthographe, de majuscules ou de grammaire."])},
  "shared.qa.language.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
  "shared.qa.locale.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreurs dans les normes nationales telles que les formats de date, les devises et les aspects culturels."])},
  "shared.qa.locale.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caractère local"])},
  "shared.qa.select_segment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un segment pour modifier le contrôle qualité"])},
  "shared.qa.status.critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grave"])},
  "shared.qa.status.critical_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éliminatoire : une erreur inacceptable, par exemple des interprétations erronées ou des erreurs pouvant entraîner des conséquences juridiques, sanitaires ou financières."])},
  "shared.qa.status.major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majeure"])},
  "shared.qa.status.major_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreurs dans le titre, instructions de traduction ignorées ou changement important de sens entraînant une mauvaise orientation ou une distorsion du produit."])},
  "shared.qa.status.minor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mineure"])},
  "shared.qa.status.minor_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreurs de ponctuation ou fautes de frappe mineures. Erreurs qui entraînent un léger changement de sens."])},
  "shared.qa.status.no-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas d'erreur"])},
  "shared.qa.style.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreurs de style, non-respect de directives spécifiques ou erreurs de registre de langue/de variante de langue."])},
  "shared.qa.style.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Style"])},
  "shared.qa.terminology.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisation incohérente de la terminologie ou non-respect du glossaire propre à l’industrie/fourni."])},
  "shared.qa.terminology.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminologie"])},
  "shared.qa.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôle de qualité"])},
  "shared.reset_password.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])},
  "shared.reset_password.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer votre e-mail pour demander une réinitialisation du mot de passe."])},
  "shared.section.text.gdpr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger et/ou supprimer vos données personnelles."])},
  "shared.section.title.transparency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La transparence est essentielle pour nous"])},
  "shared.status.action_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action requise"])},
  "shared.status.available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible"])},
  "shared.status.cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulé"])},
  "shared.status.completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminé"])},
  "shared.status.in_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À l’état de brouillon"])},
  "shared.status.in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])},
  "shared.status.in_progress_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduction en cours"])},
  "shared.status.need_approval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approbation nécessaire"])},
  "shared.status.not_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas commencé"])},
  "shared.status.pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
  "shared.status.processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours de traitement"])},
  "shared.status.project_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projet annulé"])},
  "shared.status.project_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projet créé"])},
  "shared.status.project_declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projet refusé"])},
  "shared.status.ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La traduction est prête"])},
  "shared.status.unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indisponible"])},
  "shared.stripe.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous devez terminer quelques étapes supplémentaires pour vérifier votre compte ", _interpolate(_named("stripe")), " et être en mesure de recevoir des paiements."])},
  "shared.sub_nav.history.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique"])},
  "shared.sub_nav.profile.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "shared.terms.ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je souhaite participer au programme EasyTranslate Apprentissage IA et bénéficier d'analyses plus intelligentes, plus rapides et basées sur les données de mon travail."])},
  "shared.terms.condition.btn.terms_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions"])},
  "shared.terms.conditions.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["J'accepte les ", _interpolate(_named("terms_conditions")), " EasyTranslate et la ", _interpolate(_named("privacy_policy")), ". J'accepte en outre de recevoir des informations ", _interpolate(_named("marketing")), " par e-mail de la part d'EasyTranslate A/S concernant ", _interpolate(_named("product_categories")), " ; je peux retirer mon consentement à tout moment."])},
  "shared.terms.dpa": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cliquez ", _interpolate(_named("ici")), " pour télécharger notre DPA."])},
  "shared.terms.knowledge_base.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base de connaissances"])},
  "shared.terms.processors": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cliquez sur ", _interpolate(_named("here")), " pour plus d'informations sur nos sous-traitants de données."])},
  "shared.terms.vat_id.example": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("label")), " Numéro de TVA danois : DK12345678 ; Numéro de TVA norvégien : NO123456789 MVA ; Numéro de TVA suisse : CHE 123.456.789 MWST / CHE 123.456.789 TVA / CHE 123.456.789 IVA"])},
  "shared.terms.vat_id.for_instance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par exemple :"])},
  "shared.terms.vat_id.requirment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaque numéro d'identification TVA doit commencer par le code du pays concerné et être suivi d'un ensemble de chiffres et/ou de caractères."])},
  "shared.text.create_your_client_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez votre compte EasyTranslate"])},
  "shared.text.iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* En fournissant votre IBAN, vous autorisez *Rocketship Inc* et Stripe, notre prestataire de services de paiement, à envoyer des instructions à votre banque pour débiter votre compte conformément à ces instructions. Les paiements ultérieurs donnent droit à un remboursement de la part de votre banque selon les conditions de votre accord avec votre banque. Un remboursement doit être réclamé dans un délai de huit semaines à compter de la date à laquelle votre compte a été débité."])},
  "shared.text.other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
  "shared.text.steps_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étapes terminées"])},
  "shared.text.upload_profile_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléverser une photo de profil"])},
  "shared.text.your_account_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les informations de votre compte"])},
  "shared.text.your_billing_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos informations de facturation"])},
  "shared.text.your_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mode de paiement"])},
  "shared.title.hygraph_pat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre PAT Hygraph"])},
  "shared.toast.error.business_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir une adresse e-mail professionnelle"])},
  "shared.toast.error.download_gdpr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre fichier n'a pas pu être téléchargé car le fichier n'est pas prêt ou l'url n'est pas valide"])},
  "shared.toast.error.no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne disposez pas des autorisations nécessaires pour effectuer cette action. Veuillez contacter l'administrateur de votre équipe (adresse e-mail de l'administrateur de l'équipe)"])},
  "shared.toast.error.redirect_stripe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s’est produite lors de votre redirection vers Stripe"])},
  "shared.toast.error.send_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre message n'a pas pu être envoyé"])},
  "shared.toast.error.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
  "shared.toast.info.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
  "shared.toast.label.freelancer_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez atteint votre limite de freelances. Veuillez supprimer un freelance avant d'en ajouter un nouveau."])},
  "shared.toast.message.file.notsupported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de fichier non pris en charge"])},
  "shared.toast.success.congrats_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Félicitations !"])},
  "shared.toast.success.data_retention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos préférences relatives au stockage des données personnelles ont été mises à jour"])},
  "shared.toast.success.finish_revision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre révision a été marquée comme terminée"])},
  "shared.toast.success.mfa_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'authentification à deux facteurs a été désactivée sur votre profil"])},
  "shared.toast.success.mfa_enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'authentification à deux facteurs a été activée pour votre profil"])},
  "shared.toast.success.password_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nouveau mot de passe a été enregistré"])},
  "shared.toast.success.privacy_consent_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos préférences de confidentialité et de consentement ont été mises à jour."])},
  "shared.toast.success.requesst_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande de suppression de ce compte a été envoyée. Le processus peut prendre jusqu'à 14 jours ouvrables."])},
  "shared.toast.success.request_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande de suppression a été envoyée et nous la traiterons très bientôt"])},
  "shared.toast.success.save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos modifications ont été enregistrées"])},
  "shared.toast.success.saved_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistré"])},
  "shared.toast.success.send_forgot_password_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez demandé à récupérer votre mot de passe. Vous recevrez bientôt un e-mail avec des instructions."])},
  "shared.toast.success.subaccount_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre sous-compte a été créé"])},
  "shared.toast.success.tag_fix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction des balises terminée"])},
  "shared.toast.success.thanks_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci !"])},
  "shared.toast.success.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réussi"])},
  "shared.toast.success.uncofirm_segments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La confirmation de tous les segments a été annulée"])},
  "shared.toast.success.update_profile_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les informations de votre profil ont été mises à jour"])},
  "shared.toast.warning.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avertissement"])},
  "shared.tooltip.documents.word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaque mot inclus dans votre document simple est comptabilisé dans le nombre de mots indiqué ci-dessous"])},
  "shared.unsupported.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez essayer d'accéder à l'éditeur à partir d'un ordinateur de bureau"])},
  "shared.unsupported.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'éditeur n'est pas pris en charge sur les écrans de petite taille"])},
  "shared.user.status.not_supported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non pris en charge"])},
  "shared.validation.password.business_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir une adresse e-mail professionnelle"])},
  "shared.validation.password.min_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit contenir au moins 6 caractères"])},
  "shared.validation.password.not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe ne correspond pas"])},
  "shared.validation.password.strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit comporter au moins 8 caractères et contenir des chiffres, des caractères spéciaux, des minuscules et des majuscules"])},
  "shared.validation.phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer un numéro de téléphone valide"])},
  "shared.voucher.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aucune carte de crédit nécessaire, recevez un bon de réduction de ", _interpolate(_named("price")), " € et accédez gratuitement à toutes nos fonctionnalités !"])},
  "supplier.account.delete_modal.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer ce compte ?"])},
  "supplier.account.delete_modal.confirm_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer le compte ", _interpolate(_named("account")), " ?"])},
  "supplier.account.delete_modal.warning_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sachez que le compte sera définitivement supprimé et que cette action est irréversible"])},
  "supplier.availability.calendar.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon calendrier"])},
  "supplier.availability.empty.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il semble que vous n'ayez pas encore prévu de période de congés"])},
  "supplier.availability.empty.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune période de congés trouvée"])},
  "supplier.availability.modal_add.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une date de début et une date de fin pour vos congés."])},
  "supplier.availability.modal_add.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une nouvelle période de congés"])},
  "supplier.availability.modal_remove.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir vous définir comme disponible aux dates suivantes ?"])},
  "supplier.availability.modal_remove.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer les congés"])},
  "supplier.availability.settings.days_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quels jours êtes-vous disponible pour travailler ?"])},
  "supplier.availability.settings.hourly_word": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle est votre production horaire en mots ?"])},
  "supplier.availability.settings.hourly_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combien d'heures/jour travaillez-vous ?"])},
  "supplier.availability.settings.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres de disponibilité"])},
  "supplier.availability.settings.work_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définissez l'heure de début et de fin de votre journée de travail (de-à)"])},
  "supplier.availability.time.btn.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des congés"])},
  "supplier.availability.time.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes congés"])},
  "supplier.clients_list.no_clients_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun client n'a encore utilisé cette prestation."])},
  "supplier.clients_list.no_clients_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun client"])},
  "supplier.content_pillars.editor.header.content_pillar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilier de contenu"])},
  "supplier.content_pillars.editor.header.intro.text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point de modifier un texte généré par l'IA à l'aide des commentaires du.de la client.e et de mots-clés pertinents."])},
  "supplier.content_pillars.editor.header.intro.text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez utiliser le matériel de référence indiqué dans la zone d'informations ci-dessus ; il fournit des informations précieuses sur le contexte et le type de contenu ainsi qu’une brève description de la tâche et des mots-clés."])},
  "supplier.content_pillars.editor.header.intro.text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le cas échéant, nous fournissons le lien vers le site Web d’où les mots-clés ont été extraits pour encore plus d’informations. Veuillez modifier soigneusement le résultat pour qu'il corresponde aux exigences du.de la client.e et utilisez si nécessaire la fonction d'invite. Assurez-vous que le champ de texte contient uniquement le texte final avant de terminer la tâche."])},
  "supplier.content_pillars.editor.header.intro.thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci !"])},
  "supplier.content_pillars.editor.header.intro.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonjour, une nouvelle tâche de rédaction publicitaire vous attend !"])},
  "supplier.content_pillars.editor.header.keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot-clé"])},
  "supplier.content_pillars.error.task_id_undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["« l'identifiant de la tâche n'est pas défini ». Nous n'avons pas pu terminer votre tâche. Veuillez la recharger et réessayer."])},
  "supplier.finish_copywriting.toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez bien terminé la tâche"])},
  "supplier.language_pair.delete.modal.confirmation_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer la prestation ", _interpolate(_named("service")), " du compte ", _interpolate(_named("account")), " ?"])},
  "supplier.language_pair.delete.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer cette paire de langues ?"])},
  "supplier.language_pair.delete.modal.warning_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sachez que la prestation sera définitivement supprimée et que cette action est irréversible"])},
  "supplier.messages.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos clients sont prêts à communiquer intelligemment avec vous concernant des tâches spécifiques"])},
  "supplier.methods.about.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EasyTranslate ne partagera jamais vos informations de paiement avec des tiers à votre insu et sans votre consentement"])},
  "supplier.methods.about.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des paiements simplifiés"])},
  "supplier.methods.btn.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir mon compte Stripe"])},
  "supplier.methods.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous utilisons Stripe pour nous assurer que vous êtes payé à temps et protéger vos coordonnées personnelles et bancaires."])},
  "supplier.methods.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stripe"])},
  "supplier.my_clients.btn.services_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les services & tarifs"])},
  "supplier.nav.availability.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma disponibilité"])},
  "supplier.nav.clients.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes clients"])},
  "supplier.nav.tasks.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes tâches"])},
  "supplier.news.03-07-2023.more_customers.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-user-friends"])},
  "supplier.news.03-07-2023.more_customers.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour être encore plus visible auprès des client.e.s potentiel.le.s, tout ce que vous avez à faire est d'accéder à votre profil puis d'accepter de partager votre profil sur notre site Web public et d'être ajouté à notre catalogue de freelances. Vous pourrez vous désinscrire à tout moment en rétablissant ce paramètre à la configuration par défaut."])},
  "supplier.news.03-07-2023.more_customers.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenez plus de client.e.s !"])},
  "supplier.news.13-10-2023.content_pillars.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-microchip"])},
  "supplier.news.13-10-2023.content_pillars.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le ou la client.e est désormais en mesure de créer une feuille de route pour un contenu optimisé SEO. Restez à l’affût des nouvelles tâches. Elles seront similaires aux tâches de rédaction, mais avec des instructions détaillées sur la façon d'appliquer correctement l'outil, afin de fournir le meilleur résultat possible à vos client.e.s."])},
  "supplier.news.13-10-2023.content_pillars.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous venons d'ajouter une nouvelle fonctionnalité : les piliers de contenu !"])},
  "supplier.news.14-04-2021.preferred_deadline.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-calendar-day"])},
  "supplier.news.14-04-2021.preferred_deadline.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre client peut maintenant définir une échéance préférée, qui peut ne pas correspondre à la disponibilité que vous avez définie. Veuillez confirmer l’échéance préférée en acceptant la tâche dans Memsource ou la refuser si vous ne pouvez pas la respecter. Si vous n'êtes pas en mesure de respecter l’échéance préférée, veuillez contacter votre client via la fonction de message de la plateforme pour vous mettre d’accord."])},
  "supplier.news.14-04-2021.preferred_deadline.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échéance préférée"])},
  "supplier.news.16-09-2020.availability.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-calendar-day"])},
  "supplier.news.16-09-2020.availability.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous aidons à calculer le délai de livraison pour vos clients avec la Disponibilité. Vous pouvez désormais vous définir comme indisponible, notamment pendant les périodes des vacances, afin que vos clients sachent quand ils peuvent s'attendre à ce que leurs traductions soient livrées."])},
  "supplier.news.16-09-2020.availability.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibilité"])},
  "supplier.news.16-09-2020.review_editor.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-envelope"])},
  "supplier.news.16-09-2020.review_editor.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous connaissons l'importance de pouvoir communiquer directement avec vos clients sur des projets spécifiques. C’est pour cela que nous introduisons Messages. Les messages sont votre moyen de communiquer directement avec vos clients. Il vous suffit d’accéder au projet concerné et de rédiger le message à votre client."])},
  "supplier.news.16-09-2020.review_editor.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditeur de révision pour le NOUVEAU type de projet « Révision »."])},
  "supplier.news.23-11-2020.review_editor.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file-alt"])},
  "supplier.news.23-11-2020.review_editor.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous pouvons enfin dévoiler notre première version de l’EasyTranslate Review Editor qui vous permet d'effectuer votre tâche de révision directement dans EasyTranslate Platform. Les révisions sont mises à jour dans la mémoire de traduction pour une amélioration instantanée des futures traductions. Vous pourrez également voir les modifications effectuées par le client après la livraison ; cela permettra d’apporter des améliorations aux projets à l’avenir."])},
  "supplier.news.23-11-2020.review_editor.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditeur de révision pour le NOUVEAU type de projet « Révision »."])},
  "supplier.news.24-11-2021.chat.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-comments"])},
  "supplier.news.24-11-2021.chat.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour vous donner une meilleure visibility sur ce que vous écrivez, nous avons reviewed and implemented le design de la saisie de la discussion dans l'éditeur. Vous n'avez plus besoin de naviguer dans ce que vous avez écrit pour le lire ou le modifier, le champ de saisie affiche désormais votre contenu sous forme de lignes pour vous donner un aperçu complet. NB. La fonctionnalité de discussion n'est disponible que lorsqu'un ou plusieurs traducteurs de votre équipe sont impliqués dans le projet."])},
  "supplier.news.24-11-2021.chat.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discuter"])},
  "supplier.news.24-11-2021.history.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-history"])},
  "supplier.news.24-11-2021.history.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'éditeur de révision d'EasyTranslate s’est enrichi d’une new functionality que vous aimerez: the history. Grâce à l'historique, gardez une trace de tous les changes apportés à un segment à un instant donné. Restore previous versions ou analyser simplement le processus de révision, c’est à vous de choisir."])},
  "supplier.news.24-11-2021.history.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique"])},
  "supplier.news.25-01-2022.client_overview.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-list"])},
  "supplier.news.25-01-2022.client_overview.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons mis en place un aperçu client, afin que vous puissiez voir all your clients in a grid view ainsi que les prestations que vous leur proposez."])},
  "supplier.news.25-01-2022.client_overview.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu client"])},
  "supplier.news.25-01-2022.new_pricing.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-dollar-sign"])},
  "supplier.news.25-01-2022.new_pricing.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons introduit un nouveau plan tarifaire pour nos clients: freemium! Lorsque les prospects s'inscrivent à ce plan, ils peuvent vous sélectionner comme traducteur et vous envoyer des projets. Le fonctionnement reste inchangé, mais sans traductions test !"])},
  "supplier.news.25-01-2022.new_pricing.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveaux tarifs"])},
  "supplier.news.25-01-2023.menu.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-bars"])},
  "supplier.news.25-01-2023.menu.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons placé la section paramètres et actualités dans le menu du profil en bas à gauche de l'application. Nous avons également déplacé la fonction Message vers la barre latérale gauche."])},
  "supplier.news.25-01-2023.menu.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changements au menu !"])},
  "supplier.news.29-03-2021.search_editor_feature.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file-alt"])},
  "supplier.news.29-03-2021.search_editor_feature.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos Translation Memory et Term Bank sont désormais accessibles et consultables dans l'outil Éditeur, afin que vous puissiez assurer la continuité en un rien de temps sans quitter la page. Utilisez le champ de recherche dans la phase de révision pour rechercher des mots et voir s'ils correspondent à votre mémoire de traduction et/ou à votre base de terminologie. Toutes les correspondances trouvées seront mises en surbrillance dans la section source de votre contenu. Besoin de surveiller le nombre de caractères ? Ces derniers sont désormais également visibles dans les champs source et cible."])},
  "supplier.news.29-03-2021.search_editor_feature.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher dans la fonction Éditeur"])},
  "supplier.onboarding.about_you.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À propos de vous"])},
  "supplier.onboarding.about_you.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dites-nous en plus sur vous"])},
  "supplier.onboarding.payout.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement"])},
  "supplier.onboarding.payout.text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous utilisons Stripe pour nous assurer que vous êtes payé à temps et protéger vos coordonnées personnelles et bancaires."])},
  "supplier.onboarding.payout.text_two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cliquez sur ", _interpolate(_named("next")), " pour configurer vos paiements sur Stripe."])},
  "supplier.onboarding.payout.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos informations de paiement"])},
  "supplier.onboarding.services.hourly_output": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle est votre production horaire en mots ?"])},
  "supplier.onboarding.services.hours_per_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combien d'heures travaillez-vous par jour ?"])},
  "supplier.onboarding.services.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos prestations"])},
  "supplier.onboarding.services.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment gérez-vous votre travail ?"])},
  "supplier.onboarding.services.whih_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quels jours êtes-vous disponible pour travailler ?"])},
  "supplier.onboarding.services.working_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelles sont vos heures de travail ? (de-à)"])},
  "supplier.onboarding.success.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finir"])},
  "supplier.onboarding.success.text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sommes ravis que vous ayez choisi de travailler avec nous ! Votre travail est important pour nous et, maintenant que vous avez terminé la configuration de votre compte, vous êtes prêt à commencer."])},
  "supplier.onboarding.success.text_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De nombreuses tâches vous attendent."])},
  "supplier.onboarding.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez votre compte fournisseur"])},
  "supplier.profile.about.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EasyTranslate ne partagera jamais vos informations personnelles avec des tiers à votre insu et sans votre consentement"])},
  "supplier.profile.about.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous ne partageons pas vos informations avec d'autres"])},
  "supplier.profile.catalogue.switch_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnectez-vous pour arrêter de partager votre profil sur notre site Web public en cliquant sur le lien suivant :"])},
  "supplier.profile.catalogue.switch_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous pour partager votre profil sur notre site Web public en cliquant sur le lien suivant :"])},
  "supplier.profile.embed_code.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En intégrant cet extrait de code dans votre site Web, votre signature électronique, etc., vous pouvez ajouter une image interactive qui redirige les utilisateur.rice.s vers votre profil EasyTranslate."])},
  "supplier.profile.embed_code.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code embed pour le lien de votre profil EasyTranslate"])},
  "supplier.profile.label.public_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien vers votre profil public"])},
  "supplier.profile.placeholder.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapez votre description ici..."])},
  "supplier.save_document.toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le document a bien été enregistré"])},
  "supplier.services.create.button_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une nouvelle prestation"])},
  "supplier.services.create.modal.button_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une prestation"])},
  "supplier.services.create.modal.select_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une langue pour le nouveau service"])},
  "supplier.services.create.modal.select_language_pair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une paire de langues pour la nouvelle prestation"])},
  "supplier.services.create.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une nouvelle prestation"])},
  "supplier.services.delete.modal.confirm_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer la prestation ", _interpolate(_named("service")), " ?"])},
  "supplier.services.delete.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer cette prestation ?"])},
  "supplier.services.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez ici voir et gérer vos prestations et leur tarification. Vous pouvez créer et supprimer des prestations ainsi que modifier leurs prix."])},
  "supplier.status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut :"])},
  "supplier.status_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marquer comme non pris en charge"])},
  "supplier.sub_nav.gdpr.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RGPD"])},
  "supplier.sub_nav.history.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique"])},
  "supplier.sub_nav.methods.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthodes"])},
  "supplier.sub_nav.services.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prestations"])},
  "supplier.task.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur la tâche"])},
  "supplier.terms.contract_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrat pour programme partenaire EasyTranslate"])},
  "supplier.terms.contract_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Je confirme avoir lu et j’accepte d'être lié par les ", _interpolate(_named("link")), " afin de travailler sur des projets linguistiques pour les clients.*"])},
  "supplier.terms.data_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'accepte de partager les données ci-dessus et de créer un compte de traducteur dans le but de travailler avec la plateforme et les clients d'EasyTranslate.*"])},
  "supplier.toast.error.empty_invitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous n'avons pas trouvé votre invitation"])},
  "supplier.toast.error.invoice_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s’est produite avec le téléchargement. Veuillez réessayer un peu plus tard."])},
  "supplier.toast.error.load_tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite lors de la tentative de chargement de vos tâches"])},
  "supplier.toast.error.open_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite lors de la tentative d'ouverture de votre tâche"])},
  "supplier.toast.error.payout_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite lors du chargement de votre historique de paiement"])},
  "supplier.toast.success.delete_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre prestation a été supprimée"])},
  "supplier.toast.success.invitations_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les invitations ont bien été envoyées aux prestataires"])},
  "supplier.toast.success.update_profile_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre photo de profil a été mise à jour"])},
  "suppliers.my_clients.title.services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services de traduction et de révision"])},
  "suppliers.my_clients.title.services_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services de traduction HumanAI"])},
  "suppliers.my_clients.title.services_copywriting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services de rédaction"])},
  "suppliers.my_clients.title.services_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services de contrôle de qualité"])}
}